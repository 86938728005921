import { createSelector } from "reselect";

// EDITOR

export const selectEditor = (state) => state.editor;

export const selectEditorReady = createSelector(
  [selectEditor],
  (editor) => editor.editorReady
);

export const selectEditorLoading = createSelector(
  [selectEditor],
  (editor) => editor.editorLoading
);

export const selectIsReadyToProceed = createSelector(
  [selectEditor],
  (editor) => editor.isReadyToProceed
);

export const selectEditorID = createSelector(
  [selectEditor],
  (editor) => editor.editorID
);

// DRAFT

// FORM STEPS

export const selectCurrentStep = createSelector(
  [selectEditor],
  (editor) => editor.currentStep
);

// TITLE AND SLUG

export const selectTitle = createSelector(
  [selectEditor],
  (editor) => editor.title
);

export const selectSlug = createSelector(
  [selectEditor],
  (editor) => editor.slug
);

export const selectIsTitleAndSlugValid = createSelector(
  [selectEditor],
  (editor) => editor.isTitleAndSlugValid
);

// THUMBNAIL

export const selectThumbnail = createSelector(
  [selectEditor],
  (editor) => editor.thumbnail
);

export const selectIsThumbnailOneValid = createSelector(
  [selectEditor],
  (editor) => editor.isThumbnailOneValid
);

export const selectIsThumbnailTwoValid = createSelector(
  [selectEditor],
  (editor) => editor.isThumbnailTwoValid
);

// VERSE

export const selectVerseList = createSelector(
  [selectEditor],
  (editor) => editor.verseList
);

export const selectCurrentVerseTextValid = createSelector(
  [selectEditor],
  (editor) => editor.isCurrentVerseTextValid
);

// VERSE TEXT

// VERSE IMAGE

export const selectCurrentVerseImageAltValid = createSelector(
  [selectEditor],
  (editor) => editor.isCurrentVerseImageAltValid
);
export const selectCurrentVerseWEBPImageValid = createSelector(
  [selectEditor],
  (editor) => editor.isCurrentVerseWEBPImageValid
);
export const selectCurrentVerseJPGImageValid = createSelector(
  [selectEditor],
  (editor) => editor.isCurrentVerseJPGImageValid
);
// LOG

// SYNC TO FIREBASE

// ERROR MESSAGES
