import PoemActionTypes from "./poem.types";

const INITIAL_STATE = {
  poem: {},
  isLoading: false,
  errorMessage: "",
};

const poemReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PoemActionTypes.DELETE_POEM_START:
    case PoemActionTypes.MARK_POEM_AS_VERIFIED_START:
    case PoemActionTypes.PUBLISH_POEM_START:
    case PoemActionTypes.UN_PUBLISH_POEM_START:
      return {
        ...state,
        poem: action.payload,
        isLoading: true,
      };
    case PoemActionTypes.DELETE_POEM_SUCCESS:
    case PoemActionTypes.MARK_POEM_AS_VERIFIED_SUCCESS:
    case PoemActionTypes.PUBLISH_POEM_SUCCESS:
    case PoemActionTypes.UN_PUBLISH_POEM_SUCCESS:
      return {
        ...state,
        poem: {},
        isLoading: false,
      };
    case PoemActionTypes.DELETE_POEM_FAIL:
    case PoemActionTypes.MARK_POEM_AS_VERIFIED_FAIL:
    case PoemActionTypes.PUBLISH_POEM_FAIL:
    case PoemActionTypes.UN_PUBLISH_POEM_FAIL:
      return {
        ...state,
        poem: {},
        isLoading: false,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};

export default poemReducer;
