export const createEditorID = () => {
  const uuidArr = crypto.randomUUID().split("-");
  const id = uuidArr.join("");
  console.log(id);
  return id;
};

export const createVerseID = (verseList) => {
  return verseList.length + 1;
};

export const setCurrentVerseTextInVerseList = (
  verseList,
  verseID,
  verseText
) => {
  const verseExists = verseList.find((verse) => verse.id === verseID);
  if (verseExists !== undefined) {
    verseList[verseID - 1].verse = verseText;
  }
  return verseList;
};

export const setVerseImageAltInVerseList = (verseList, verseID, imageAlt) => {
  const verseExists = verseList.find((verse) => verse.id === verseID);
  if (verseExists !== undefined) {
    verseList[verseID - 1].verseImageAlt = imageAlt;
  }

  return verseList;
};

export const setVerseImageUploadedInVerseList = (
  verseList,
  verseID,
  isUploaded,
  verseImage
) => {
  const verseExists = verseList.find((verse) => verse.id === verseID);
  if (verseExists !== undefined) {
    verseList[verseID - 1].verseImageUploaded = isUploaded;
    verseList[verseID - 1].verseImage = verseImage;
  }

  return verseList;
};

export const addNewVerseToVerseList = (verseList, verseID) => {
  const verseExists = verseList.find((verse) => verse.id === verseID);

  if (verseExists === undefined) {
    verseList.push({
      id: verseID,
      verse: "",
      verseImageUploaded: false,
      verseImageAlt: "",
    });
  }

  return verseList;
};

export const removeLastVerseFromVerseList = (verseList, verseID) => {
  const verseExists = verseList.find((verse) => verse.id === verseID);

  if (verseExists) {
    const count = verseList.pop();
  }

  return verseList;
};
