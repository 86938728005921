import React, { Fragment, useState, useEffect } from "react";
import { Card, Col, Form } from "react-bootstrap";
import { connect } from "react-redux";

import styled from "styled-components";
import {
  checkCurrentVerseTextOnLoad,
  setCurrentVerseText,
} from "../../redux/editor/editor.actions";
import { selectVerseList } from "../../redux/editor/editor.selectors";
import SpinnerOverlay from "../SpinnerOverlay/SpinnerOverlay";

export const StyledText = styled.p`
  white-space: pre;
`;

const VerseFormField = ({
  verseID,
  verseList,
  setCurrentVerseText,
  checkCurrentVerseTextValid,
}) => {
  const [text, setText] = useState("");
  const [textLoading, setTextLoading] = useState(false);

  useEffect(() => {
    if (verseList[verseID - 1]) {
      setText(verseList[verseID - 1].verse);
    }
  }, [verseID]);

  useEffect(() => {
    if (verseList[verseID - 1]) {
      checkCurrentVerseTextValid({ verseText: verseList[verseID - 1].verse });
    }
  }, [text]);

  const handleChange = (e) => {
    e.preventDefault();
    setTextLoading(true);
    const delayDebounceFn = setTimeout(() => {
      setCurrentVerseText({ id: verseID, verseText: e.target.value });
      setTextLoading(false);
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  };

  const handleTextChange = (e) => {
    e.preventDefault();
    setText(e.target.value);
    handleChange(e);
  };

  return (
    <Fragment>
      {verseList.length < verseID ? (
        <SpinnerOverlay spinnerText="pillanat..." />
      ) : (
        <Fragment>
          <Col md={6} className="mb-4">
            <Form.Control
              as="textarea"
              rows={16}
              onChange={handleTextChange}
              value={text}
            />
          </Col>
          <Col md={6}>
            {textLoading || verseList[verseID - 1] === undefined ? (
              <SpinnerOverlay spinnerText="előnézet töltődik" />
            ) : (
              <Card border="primary">
                <Card.Header>Előnézet</Card.Header>
                <Card.Body>
                  <StyledText className="text-center">
                    {verseList[verseID - 1].verse}
                  </StyledText>
                </Card.Body>
              </Card>
            )}
          </Col>
        </Fragment>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  verseList: selectVerseList(state),
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentVerseText: (verseText) => dispatch(setCurrentVerseText(verseText)),
  checkCurrentVerseTextValid: (verseText) =>
    dispatch(checkCurrentVerseTextOnLoad(verseText)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerseFormField);
