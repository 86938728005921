import React, { useState } from "react";
import { connect } from "react-redux";
import { Form, Button, FloatingLabel } from "react-bootstrap";
import { signInWithEmailPasswordStart } from "../../redux/user/user.actions";

const defaultFormFields = {
  email: "",
  password: "",
};

const SignInForm = ({ signIn }) => {
  const [formFields, setFormFields] = useState(defaultFormFields);
  const { email, password } = formFields;

  const resetFormFields = () => {
    setFormFields(defaultFormFields);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormFields({ ...formFields, [name]: value });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    signIn(formFields);
    resetFormFields();
  };
  return (
    <Form>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <FloatingLabel
          controlId="floatingInput"
          label="Email Cim"
          className="mb-3"
        >
          <Form.Control
            type="email"
            placeholder="Enter email"
            name="email"
            value={email}
            onChange={handleChange}
          />
        </FloatingLabel>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <FloatingLabel controlId="floatingPassword" label="Jelszo">
          <Form.Control
            type="password"
            placeholder="Password"
            name="password"
            value={password}
            onChange={handleChange}
          />
        </FloatingLabel>
      </Form.Group>

      <Button variant="primary" onClick={handleSubmit}>
        Bejelentkezés
      </Button>
    </Form>
  );
};

const mapDispatchToProps = (dispatch) => ({
  signIn: (loginDetails) =>
    dispatch(signInWithEmailPasswordStart(loginDetails)),
});

export default connect(null, mapDispatchToProps)(SignInForm);
