import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Container, Row, Col, Button, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import {
  checkIfReadyToProceedStart,
  clearDraftStart,
  nextStepStart,
  prevStepStart,
  startEditorStart,
  uploadPoemToPoemListInFirestoreStart,
} from "../../redux/editor/editor.actions";
import {
  selectCurrentStep,
  selectCurrentVerseTextValid,
  selectCurrentVerseImageAltValid,
  selectCurrentVerseJPGImageValid,
  selectCurrentVerseWEBPImageValid,
  selectEditorLoading,
  selectEditorReady,
  selectIsReadyToProceed,
  selectIsThumbnailOneValid,
  selectIsThumbnailTwoValid,
  selectIsTitleAndSlugValid,
  selectSlug,
  selectEditorID,
} from "../../redux/editor/editor.selectors";

import SpinnerOverlay from "../SpinnerOverlay/SpinnerOverlay";
import TitleAndSlugFormField from "../TitleAndSlugFormField/TitleAndSlugFormField";
import ImageFormField from "../ImageFormField/ImageFormField";
import VerseFormField from "../VerseFormField/VerseFormField";
import VerseImageAltFormField from "../VerseImageAltFormField/VerseImageAltFormField";

export const BackButton = styled(Button)``;
export const ClearDraftButton = styled(Button)``;
export const UploadButton = styled(Button)``;

const AddPoemForm = ({
  startEditor,
  editorReady,
  editorLoading,
  editorID,
  currentStep,
  nextStep,
  prevStep,
  slug,
  isVerseImageAltValid,
  checkIfReadyToProceed,
  isReadyToProceed,
  clearDraft,
  uploadDraft,
}) => {
  const navigate = useNavigate();
  const [clearDraftMessageShow, setClearDraftMessageShow] = useState(false);
  const [saveDraftMessageShow, setSaveDraftMessageShow] = useState(false);

  useEffect(() => {
    startEditor();
  }, []);
  useEffect(() => {
    checkIfReadyToProceed();
  }, [currentStep]);

  const handleNextClick = () => {
    nextStep();
  };
  const handlePrevClick = () => {
    prevStep();
  };

  const handleSaveDraftOptionClick = () => {
    setSaveDraftMessageShow(true);
  };

  const handleSaveDraftStartClick = () => {
    uploadDraft();
    navigate(`/mesek#${editorID}`);
  };
  const handleClearDraftOptionClick = () => {
    setClearDraftMessageShow(true);
  };
  const handleClearDraftStartClick = () => {
    clearDraft();
    navigate(`/mesek`);
  };

  return (
    <Container>
      {!editorReady || editorLoading ? (
        <SpinnerOverlay spinnerText="eszközök betöltése" />
      ) : isReadyToProceed && clearDraftMessageShow ? (
        <Alert
          show={clearDraftMessageShow}
          variant="danger"
          className="text-center"
        >
          <Alert.Heading>
            Biztos hogy törölni akarod a piszkozatot?
          </Alert.Heading>
          <hr />
          <Row className="my-3">
            <em>A piszkozat törlésével elveszik az eddigi munkád :(</em>
          </Row>
          <hr />
          <Row>
            <Col>
              <div className="d-flex justify-content-start">
                <Button
                  onClick={() => setClearDraftMessageShow(false)}
                  variant="warning"
                  className="d-flex justify-content-start"
                  size="lg"
                >
                  Vissza
                </Button>
              </div>
            </Col>
            <Col>
              <div className="d-flex justify-content-end">
                <Button
                  onClick={handleClearDraftStartClick}
                  variant="danger"
                  className="d-flex justify-content-end"
                  size="lg"
                >
                  Piszkozat törlése
                </Button>
              </div>
            </Col>
          </Row>
        </Alert>
      ) : isReadyToProceed && saveDraftMessageShow ? (
        <Alert
          show={saveDraftMessageShow}
          variant="success"
          className="text-center"
        >
          <Alert.Heading>Biztos hogy feltöltöd a piszkozatot?</Alert.Heading>
          <hr />
          <Row className="my-3">
            <em>
              Még nem lesz látható a nagyközönség előtt, de mi már látni fogjuk
              :)
            </em>
          </Row>
          <Row className="my-3">
            <em>
              Miután feltöltötted, ellenőrizni kell es utána publikálni hogy
              mindenki a csodajara járhasson:) &lt;3
            </em>
          </Row>
          <hr />
          <Row>
            <Col>
              <div className="d-flex justify-content-start">
                <Button
                  onClick={() => setSaveDraftMessageShow(false)}
                  variant="warning"
                  className="d-flex justify-content-start"
                  size="lg"
                >
                  Vissza
                </Button>
              </div>
            </Col>
            <Col>
              <div className="d-flex justify-content-end">
                <Button
                  onClick={handleSaveDraftStartClick}
                  variant="primary"
                  className="d-flex justify-content-end"
                  size="lg"
                >
                  Piszkozat mentese
                </Button>
              </div>
            </Col>
          </Row>
        </Alert>
      ) : (
        <Fragment>
          {currentStep === 1 ? (
            <Row className="my-3">
              <h2>Mi lesz a címe?</h2>
              <TitleAndSlugFormField />
            </Row>
          ) : currentStep === 2 ? (
            <Fragment>
              <Row className="my-4">
                <h2>Borito Kepek</h2>
              </Row>
              <Row className="my-4">
                <ImageFormField
                  fileType="webp"
                  thumbnailOne
                  slug={slug}
                  verseID={null}
                />
              </Row>
              <Row className="my-4">
                <ImageFormField
                  fileType="jpg"
                  thumbnailTwo
                  slug={slug}
                  verseID={null}
                />
              </Row>
            </Fragment>
          ) : (
            <Fragment>
              <Row className="my-5">
                <h2>{currentStep - 2}. Vers részlet</h2>
                <VerseFormField verseID={currentStep - 2} />
              </Row>
              <Row>
                <VerseImageAltFormField verseID={currentStep - 2} />
              </Row>
              <Row>
                <Col md={6}>
                  <ImageFormField
                    fileType="webp"
                    verseID={currentStep - 2}
                    isDisabled={!isVerseImageAltValid}
                  />
                </Col>
                <Col md={6}>
                  <ImageFormField
                    fileType="jpg"
                    verseID={currentStep - 2}
                    isDisabled={!isVerseImageAltValid}
                  />
                </Col>
              </Row>
            </Fragment>
          )}

          <Row className="my-5 align-items-center d-flex justify-content-center">
            <Col>
              <Button
                onClick={handleNextClick}
                disabled={!isReadyToProceed}
                variant={isReadyToProceed ? "success" : "danger"}
                size="lg"
                className="text-uppercase w-100"
              >
                {currentStep === 1
                  ? "Tovább a boritó képekhez"
                  : currentStep === 2
                  ? "Tovább az első versrészlethez"
                  : "További versszöveg"}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md={4} className="order-sm-0">
              {currentStep > 2 && (
                <Button
                  onClick={handlePrevClick}
                  variant="warning"
                  size="lg"
                  className="text-uppercase w-100 my-3 mx-auto"
                >
                  vissza
                </Button>
              )}
            </Col>
            <Col md={4}>
              <Button
                onClick={handleClearDraftOptionClick}
                size="lg"
                variant="danger"
                className="w-100 my-3 mx-auto"
              >
                Piszkozat törlése
              </Button>
            </Col>
            {isReadyToProceed && currentStep > 2 && (
              <Col md={4}>
                <Button
                  onClick={handleSaveDraftOptionClick}
                  size="lg"
                  variant="primary"
                  className="w-100 my-3 mx-auto"
                >
                  Piszkozat feltöltése a versek közé
                </Button>
              </Col>
            )}
          </Row>
        </Fragment>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  editorReady: selectEditorReady(state),
  editorLoading: selectEditorLoading(state),
  isReadyToProceed: selectIsReadyToProceed(state),
  editorID: selectEditorID(state),
  currentStep: selectCurrentStep(state),
  slug: selectSlug(state),
  isTitleAndSlugValid: selectIsTitleAndSlugValid(state),
  isThumbnailOneValid: selectIsThumbnailOneValid(state),
  isThumbnailTwoValid: selectIsThumbnailTwoValid(state),
  isVerseValid: selectCurrentVerseTextValid(state),
  isVerseImageAltValid: selectCurrentVerseImageAltValid(state),
  isVerseWEBPImageValid: selectCurrentVerseWEBPImageValid(state),
  isVerseJPGImageValid: selectCurrentVerseJPGImageValid(state),
});

const mapDispatchToProps = (dispatch) => ({
  startEditor: () => dispatch(startEditorStart()),
  nextStep: () => dispatch(nextStepStart()),
  prevStep: () => dispatch(prevStepStart()),
  checkIfReadyToProceed: () => dispatch(checkIfReadyToProceedStart()),
  clearDraft: () => dispatch(clearDraftStart()),
  uploadDraft: () => dispatch(uploadPoemToPoemListInFirestoreStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddPoemForm);
