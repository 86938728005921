import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import { layoutBreakpoints } from "../../global-styles/layoutBreakpoints";

export const StyledLink = styled(NavLink)`
  font-size: 1.1em;
  @media (min-width: ${layoutBreakpoints.xs}) {
    font-size: 1.4em;
    margin-right: 30px;
  }
`;

export const ThemeSelectorContainer = styled.div`
  cursor: pointer;
  width: 47px;
  border: solid 2px ${(props) => props.theme.primaryColor};
  border-radius: 16px;
  display: flex;
  align-items: center;
  overflow: hidden;
`;

export const ThemeSymbol = styled.div`
  padding: 2px 2px 0 2px;
`;

export const ThemeToggleButton = styled.div`
  height: 1.2em;
  width: 1.2em;
  margin: 0 2px;
  border: 1px solid
    ${(props) =>
      props.theme.name === "lightTheme"
        ? props.theme.secondaryBackgroundColor
        : props.theme.primaryColor};
  border-radius: 16px;
  background-color: ${(props) =>
    props.theme.name === "lightTheme"
      ? props.theme.primaryBackgroundColor
      : props.theme.primaryColor};
`;
