import React from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import DashboardItemsList from "../../components/DashboardItemsList/DashboardItemsList";
import { selectUserFirstName } from "../../redux/user/user.selectors";

const DashBoardPage = ({ firstName }) => {
  return (
    <Container>
      <h2>Üdv {firstName}!</h2>
      <DashboardItemsList />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  firstName: selectUserFirstName(state),
});

export default connect(mapStateToProps)(DashBoardPage);
