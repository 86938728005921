import React from "react";
import { Container } from "react-bootstrap";
import SignInForm from "../../components/SignInForm/SignInForm";

const SignInPage = () => {
  return (
    <Container>
      <h1>Bejelentkezés</h1>
      <SignInForm />
    </Container>
  );
};

export default SignInPage;
