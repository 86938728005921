import React from "react";
import styled from "styled-components";
import { FloatingLabel, Form } from "react-bootstrap";

const StyledFloatingLabel = styled(FloatingLabel)`
  color: ${(props) => props.theme.primaryColor};

  label {
    text-align: center;
    width: 100%;
    padding: 0;
    top: 30%;
    left: 0%;
  }
`;
const StyledFormControl = styled(Form.Control)`
  font-size: 1.3em;
  border: solid 3px ${(props) => props.theme.secondaryBackgroundColor};
`;

const TextFormField = ({ label, required, name, value, handleChange }) => {
  return (
    <StyledFloatingLabel
      controlId="floatingInput"
      label={label}
      className="mb-3"
    >
      <StyledFormControl
        type="text"
        placeholder={label}
        required={required}
        name={name}
        value={value}
        onChange={handleChange}
      />
    </StyledFloatingLabel>
  );
};

export default TextFormField;
