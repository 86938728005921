import UserActionTypes from "./user.types";

const INITIAL_STATE = {
  isAuthenticated: false,
  email: "",
  isAdmin: false,
  isStaff: false,
  firstName: "",
  userName: "",
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.CLEAR_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: false,
        email: "",
        isAdmin: false,
        isStaff: false,
        firstName: "",
        userName: "",
      };
    case UserActionTypes.SET_CURRENT_USER_SUCCESS:
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        email: action.payload.email,
        isAdmin: action.payload.isAdmin,
        isStaff: action.payload.isStaff,
        firstName: action.payload.firstName,
        userName: action.payload.userName,
      };
    case UserActionTypes.CHECK_CURRENT_USER:
    case UserActionTypes.SET_CURRENT_USER_START:
    case UserActionTypes.SET_CURRENT_USER_FAIL:
    case UserActionTypes.SIGN_IN_WITH_EMAIL_PASSWORD_START:
    case UserActionTypes.SIGN_IN_WITH_EMAIL_PASSWORD_SUCCESS:
    case UserActionTypes.SIGN_IN_WITH_EMAIL_PASSWORD_FAIL:
    case UserActionTypes.SIGN_OUT_START:
    case UserActionTypes.SIGN_OUT_SUCCESS:
    case UserActionTypes.SIGN_OUT_FAIL:
    case UserActionTypes.LOG_USER_ACTION_START:
    case UserActionTypes.LOG_USER_ACTION_SUCCESS:
    case UserActionTypes.LOG_USER_ACTION_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default userReducer;
