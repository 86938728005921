import {
  all,
  call,
  delay,
  put,
  race,
  select,
  takeEvery,
} from "redux-saga/effects";
import {
  deletePoemInFirestore,
  markPoemAsPublishedInFirestore,
  markPoemAsUnPublishedInFirestore,
  markPoemAsVerifiedInFirestore,
} from "../../utils/firebase/firebase.utils";
import {
  deletePoemFail,
  deletePoemSuccess,
  markPoemAsVerifiedFail,
  markPoemAsVerifiedSuccess,
  publishPoemFail,
  publishPoemSuccess,
  unPublishPoemFail,
  unPublishPoemSuccess,
} from "./poem.actions";
import PoemActionTypes from "./poem.types";

export const selectUser = (state) => state.user;

export const selectUserEmail = (state) => state.user.email;

// DELETE POEM
export function* deletePoem(action) {
  try {
    const userEmail = yield select(selectUserEmail);
    const { success, failure } = yield race({
      success: deletePoemInFirestore(action.payload, userEmail),
      failure: delay(3000),
    });
    if (!failure) {
      yield put(
        deletePoemSuccess({
          poem: action.payload,
          userEmail: userEmail,
          userAction: "deletePoem",
        })
      );
    } else {
      yield put(deletePoemFail("keres tul sokaig tartott"));
    }
  } catch (e) {
    yield console.log(e.message);

    yield put(deletePoemFail(e.message));
  }
}

export function* onPoemDeletion() {
  yield takeEvery([PoemActionTypes.DELETE_POEM_START], deletePoem);
}

// MARK AS VERIFIED

export function* markAsVerified(action) {
  try {
    const userEmail = yield select(selectUserEmail);
    const { success, failure } = yield race({
      success: markPoemAsVerifiedInFirestore(action.payload, userEmail),
      failure: delay(3000),
    });
    if (!failure) {
      yield put(
        markPoemAsVerifiedSuccess({
          poem: action.payload,
          userEmail: userEmail,
          userAction: "verifyPoem",
        })
      );
    } else {
      yield put(markPoemAsVerifiedFail("keres tul sokaig tartott"));
    }
  } catch (e) {
    yield console.log(e.message);

    yield put(markPoemAsVerifiedFail(e.message));
  }
}

export function* onMarkingPoemAsVerified() {
  yield takeEvery(
    [PoemActionTypes.MARK_POEM_AS_VERIFIED_START],
    markAsVerified
  );
}

// PUBLISH / UNPUBLISH

export function* publishPoem(action) {
  try {
    const userEmail = yield select(selectUserEmail);
    const { success, failure } = yield race({
      success: markPoemAsPublishedInFirestore(action.payload, userEmail),
      failure: delay(3000),
    });
    if (!failure) {
      yield put(
        publishPoemSuccess({
          poem: action.payload,
          userEmail: userEmail,
          userAction: "publishPoem",
        })
      );
    } else {
      yield put(publishPoemFail("keres tul sokaig tartott"));
    }
  } catch (e) {
    yield console.log(e.message);

    yield put(publishPoemFail(e.message));
  }
}

export function* onPublishingPoem() {
  yield takeEvery([PoemActionTypes.PUBLISH_POEM_START], publishPoem);
}

export function* unPublishPoem(action) {
  try {
    const userEmail = yield select(selectUserEmail);
    const { success, failure } = yield race({
      success: markPoemAsUnPublishedInFirestore(action.payload, userEmail),
      failure: delay(3000),
    });
    if (!failure) {
      yield put(
        unPublishPoemSuccess({
          poem: action.payload,
          userEmail: userEmail,
          userAction: "unPublishPoem",
        })
      );
    } else {
      yield put(unPublishPoemFail("keres tul sokaig tartott"));
    }
  } catch (e) {
    yield console.log(e.message);

    yield put(unPublishPoemFail(e.message));
  }
}

export function* onUnPublishingPoem() {
  yield takeEvery([PoemActionTypes.UN_PUBLISH_POEM_START], unPublishPoem);
}

//  POEM SAGAS

export function* poemSagas() {
  yield all([
    call(onPoemDeletion),
    call(onMarkingPoemAsVerified),
    call(onPublishingPoem),
    call(onUnPublishingPoem),
  ]);
}
