import {
  all,
  call,
  delay,
  put,
  race,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  auth,
  getCurrentUserAccessLevel,
  logUserActionOnPoemsInFirestore,
  signInAuthUserWithEmailAndPassword,
} from "../../utils/firebase/firebase.utils";
import PoemActionTypes from "../poem/poem.types";
import {
  clearCurrentUser,
  logUserActionFail,
  logUserActionStart,
  logUserActionSuccess,
  setCurrentUserFail,
  setCurrentUserStart,
  setCurrentUserSuccess,
  signInWithEmailPasswordFail,
  signInWithEmailPasswordSuccess,
  signOutFail,
  signOutSuccess,
} from "./user.actions";
import UserActionTypes from "./user.types";

export function* signInWithEmailPassword(action) {
  try {
    const response = yield signInAuthUserWithEmailAndPassword(
      action.payload.email,
      action.payload.password
    );
    if (response) {
      yield put(signInWithEmailPasswordSuccess());
    } else {
      yield put(signInWithEmailPasswordFail("nem sikerult bejelentkezni"));
    }
  } catch (error) {
    switch (error.code) {
      case "auth/wrong-password":
        alert("incorrect password for email");
        yield put(signInWithEmailPasswordFail(error.message));
        break;
      case "auth/user-not-found":
        alert("no user associated with this email");
        yield put(signInWithEmailPasswordFail(error.message));
        break;
      default:
        console.log(error);
        yield put(signInWithEmailPasswordFail(error.message));
    }
    yield put(signInWithEmailPasswordFail(error.message));
  }
}

export function* checkingCurrentUser(action) {
  try {
    yield put(setCurrentUserStart());
    const userInfo = yield getCurrentUserAccessLevel();
    if (userInfo) {
      const payload = {
        isAuthenticated: true,
        email: userInfo.email,
        isAdmin: userInfo.isAdmin,
        isStaff: userInfo.isStaff,
        firstName: userInfo.firstName,
        userName: userInfo.userName,
      };
      yield put(setCurrentUserSuccess(payload));
    } else {
      yield put(setCurrentUserFail("nem talaltam ilyen felhasznalot"));
    }
  } catch (e) {
    yield put(setCurrentUserFail(e.message));
  }
}

export function* signOutUser(action) {
  try {
    yield auth.signOut();
    yield put(clearCurrentUser());

    yield put(signOutSuccess());
  } catch (e) {
    yield put(signOutFail(e.message));
  }
}

export function* onSignInWithEmailPassword() {
  yield takeLatest(
    [UserActionTypes.SIGN_IN_WITH_EMAIL_PASSWORD_START],
    signInWithEmailPassword
  );
}

export function* onCheckingCurrentUser() {
  yield takeLatest(
    [
      UserActionTypes.SIGN_IN_WITH_EMAIL_PASSWORD_SUCCESS,
      UserActionTypes.SIGN_IN_WITH_EMAIL_PASSWORD_FAIL,
      UserActionTypes.CHECK_CURRENT_USER,
    ],
    checkingCurrentUser
  );
}

export function* onSignOutUser() {
  yield takeLatest([UserActionTypes.SIGN_OUT_START], signOutUser);
}
// LOG USER ACTIONS

export function* logUserAction(action) {
  try {
    yield put(logUserActionStart());
    const { success, failure } = yield race({
      success: logUserActionOnPoemsInFirestore(
        action.payload.poem,
        action.payload.userEmail,
        action.payload.userAction
      ),
      failure: delay(3000),
    });
    yield console.log("success");
    if (!failure) {
      yield put(logUserActionSuccess());
    } else {
      yield put(logUserActionFail("keres tul sokaig tartott"));
    }
  } catch (e) {
    yield put(logUserActionFail(e.message));
  }
}

export function* onMajorUserActions() {
  yield takeEvery(
    [
      PoemActionTypes.DELETE_POEM_SUCCESS,
      PoemActionTypes.MARK_POEM_AS_VERIFIED_SUCCESS,
      PoemActionTypes.PUBLISH_POEM_SUCCESS,
      PoemActionTypes.UN_PUBLISH_POEM_SUCCESS,
    ],
    logUserAction
  );
}

export function* userSagas() {
  yield all([
    call(onSignInWithEmailPassword),
    call(onCheckingCurrentUser),
    call(onSignOutUser),
    call(onMajorUserActions),
  ]);
}
