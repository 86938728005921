import React, { useEffect, useRef, useState, Fragment } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import {
  PoemContainer,
  BackButton,
  StyledText,
  GridContainer,
  TopLeftColoredBox,
  BottomRightColoredBox,
  MiddleContainer,
  VerseImage,
} from "./PoemPage.styles";

import { selectAllPoems } from "../../redux/poems/poems.selectors";
import { loadPoemsStart } from "../../redux/poems/poems.actions";
import SpinnerOverlay from "../../components/SpinnerOverlay/SpinnerOverlay";

const PoemPage = ({ allPoems, loadPoems }) => {
  const navigate = useNavigate();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  let poem = useRef({});

  useEffect(() => {
    if (!allPoems.length) {
      navigate("/mesek");
    } else {
      const p = allPoems.find((p) => p.slug === params.poemSlug);

      poem.current = p;
      if (poem.current.slug === params.poemSlug) {
        setIsLoading(false);
      }
    }
  }, [params.poemSlug, allPoems, loadPoems, poem, navigate]);

  return (
    <PoemContainer>
      <BackButton onClick={() => navigate(-1)}>← Vissza</BackButton>

      {isLoading ? (
        <SpinnerOverlay loadingText="Vers töltődik ..." />
      ) : (
        <Fragment>
          <h1 className="m-4 text-center">{poem.current.title}</h1>

          {poem.current.verseList.map((v) => (
            <Row key={v.id} className="m-2">
              <Col
                sm={12}
                md={12}
                lg={6}
                className="d-flex justify-content-center align-items-center text-center"
              >
                <StyledText>{v.verse}</StyledText>
              </Col>

              <Col
                sm={12}
                md={12}
                lg={6}
                className="d-flex justify-content-center align-items-center"
              >
                {v.verseImage.length ? (
                  <GridContainer>
                    <TopLeftColoredBox />
                    <MiddleContainer>
                      <VerseImage>
                        <source
                          srcSet={`https://firebasestorage.googleapis.com/v0/b/versesmesek-706db.appspot.com/o/static%2Fimages%2F${poem.current.slug}%2F${v.verseImage}.webp?alt=media`}
                          type="image/webp"
                        />
                        <img
                          src={`this.src=https://firebasestorage.googleapis.com/v0/b/versesmesek-706db.appspot.com/o/static%2Fimages%2F${poem.current.slug}%2F${v.verseImage}.jpg?alt=media`}
                          alt={v.verseImageAlt}
                          type="image/jpg"
                        />
                      </VerseImage>
                    </MiddleContainer>
                    <BottomRightColoredBox />
                  </GridContainer>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          ))}
          {poem.current.verseList.length > 1 && (
            <BackButton className="my-2" onClick={() => navigate(-1)}>
              ← Vissza
            </BackButton>
          )}
        </Fragment>
      )}
    </PoemContainer>
  );
};

const mapStateToProps = (state) => ({
  allPoems: selectAllPoems(state),
});

const mapDispatchToProps = (dispatch) => ({
  loadPoems: () => dispatch(loadPoemsStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PoemPage);
