import {
  all,
  call,
  put,
  takeEvery,
  race,
  delay,
  take,
  select,
  takeLatest,
} from "redux-saga/effects";

import EditorActionTypes from "./editor.types";
import {
  addLogEntry,
  checkIfReadyToProceedFail,
  checkIfReadyToProceedSuccess,
  clearDraftFail,
  clearDraftStart,
  clearDraftSuccess,
  createDraft,
  createVerseFail,
  createVerseStart,
  createVerseSuccess,
  deleteEmptyVerseFail,
  deleteEmptyVerseStart,
  deleteEmptyVerseSuccess,
  nextStepFail,
  nextStepSuccess,
  prevStepFail,
  prevStepSuccess,
  setCurrentVerseTextValid,
  setEditorLoading,
  setReadyToProceed,
  setTitleAndSlugSuccess,
  setTitleAndSlugValid,
  setVerseImageAltValid,
  setVerseJPGImageValid,
  setVerseWEBPImageValid,
  startEditorFail,
  startEditorSuccess,
  syncChangesToFirebaseFail,
  syncChangesToFirebaseStart,
  syncChangesToFirebaseSuccess,
  uploadPoemToPoemListInFirestoreFail,
  uploadPoemToPoemListInFirestoreSuccess,
} from "./editor.actions";
import {
  fetchCurrentPoemFromFirestore,
  saveEditedPoemInFirestore,
  uploadEditorStateToFireStore,
} from "../../utils/firebase/firebase.utils";

import {
  addNewVerseToVerseList,
  removeLastVerseFromVerseList,
} from "./editor.utils";

export const selectEditor = (state) => state.editor;
export const selectEditorID = (state) => state.editor.editorID;
export const selectUserEmail = (state) => state.user.email;
export const selectCurrentStep = (state) => state.editor.currentStep;
export const selectVerseList = (state) => state.editor.verseList;
export const selectIsTitleAndSlugValid = (state) =>
  state.editor.isTitleAndSlugValid;
export const selectIsThumbnailOneValid = (state) =>
  state.editor.isThumbnailOneValid;
export const selectIsThumbnailTwoValid = (state) =>
  state.editor.isThumbnailTwoValid;
export const selectIsCurrentVerseTextValid = (state) =>
  state.editor.isCurrentVerseTextValid;
export const selectIsCurrentVerseImageAltValid = (state) =>
  state.editor.isCurrentVerseImageAltValid;

export const selectIsCurrentVerseWEBPImageValid = (state) =>
  state.editor.isCurrentVerseImageWEBPValid;
export const selectIsCurrentVerseJPGImageValid = (state) =>
  state.editor.isCurrentVerseImageJPGValid;

// EDITOR SETUP

export function* startEditor(action) {
  try {
    const editorID = yield select(selectEditorID);
    const userEmail = yield select(selectUserEmail);
    if (editorID.length) {
      yield put(
        addLogEntry({
          user: userEmail,
          action: "open",
          poemID: editorID,
          timestamp: new Date().toISOString(),
        })
      );
    } else {
      yield put(createDraft());
      yield put(
        addLogEntry({
          user: userEmail,
          action: "create",
          poemID: editorID,
          timestamp: new Date().toISOString(),
        })
      );
    }
    yield put(startEditorSuccess());
  } catch (e) {
    const editorID = yield select(selectEditorID);
    const userEmail = yield select(selectUserEmail);
    yield put(
      addLogEntry({
        user: userEmail,
        action: "errorOnEditorStart",
        poemID: editorID,
      })
    );
    yield put(startEditorFail(e.message));
  }
}

export function* onStartingEditor() {
  yield takeEvery([EditorActionTypes.START_EDITOR_START], startEditor);
}

// EDITOR READYTOPROCEED

export function* checkEditorIsReadyToProcced(action) {
  try {
    const currentStep = yield select(selectCurrentStep);
    if (currentStep === 1) {
      const titleAndSLugValid = yield select(selectIsTitleAndSlugValid);
      if (titleAndSLugValid) {
        yield put(setReadyToProceed(true));
      } else {
        yield put(setReadyToProceed(false));
      }
    } else if (currentStep === 2) {
      const thumbnailOneValid = yield select(selectIsThumbnailOneValid);
      const thumbnailTwoValid = yield select(selectIsThumbnailTwoValid);
      if (thumbnailOneValid && thumbnailTwoValid) {
        yield put(setReadyToProceed(true));
      } else {
        yield put(setReadyToProceed(false));
      }
    } else if (currentStep > 2) {
      const verseValid = yield select(selectIsCurrentVerseTextValid);
      if (verseValid) {
        const imageAltValid = yield select(selectIsCurrentVerseImageAltValid);
        const WEBPValid = yield select(selectIsCurrentVerseWEBPImageValid);
        const JPGValid = yield select(selectIsCurrentVerseJPGImageValid);
        if (imageAltValid) {
          if (WEBPValid && JPGValid) {
            yield put(setReadyToProceed(true));
          } else {
            yield put(setReadyToProceed(false));
          }
        } else if (!imageAltValid) {
          if (WEBPValid || JPGValid) {
            yield put(setReadyToProceed(false));
          } else {
            yield put(setReadyToProceed(true));
          }
        }
      } else {
        yield put(setReadyToProceed(false));
      }
    }
    yield put(checkIfReadyToProceedSuccess());
  } catch (e) {
    const editorID = yield select(selectEditorID);
    const userEmail = yield select(selectUserEmail);
    yield put(
      addLogEntry({
        user: userEmail,
        action: "errorOnCheckingEditorReadyToProcced",
        poemID: editorID,
      })
    );
    yield put(checkIfReadyToProceedFail(e.message));
  }
}

export function* onCheckingEditorIsReadyToProceed() {
  yield takeLatest(
    [
      EditorActionTypes.CHECK_IF_READY_TO_PROCEED_START,
      EditorActionTypes.SET_TITLE_AND_SLUG_VALID,
      EditorActionTypes.SET_THUMBNAIL_ONE_VALID,
      EditorActionTypes.SET_THUMBNAIL_TWO_VALID,
      EditorActionTypes.SET_CURRENT_VERSE_TEXT_VALID,
      EditorActionTypes.SET_VERSE_IMAGE_ALT_VALID,
      EditorActionTypes.SET_VERSE_WEBP_IMAGE_VALID,
      EditorActionTypes.SET_VERSE_JPG_IMAGE_VALID,
    ],
    checkEditorIsReadyToProcced
  );
}

// HANDLE NEXT/PREV BUTTON CLICK ACTIONS
export function* goToNextFormStep(action) {
  try {
    yield put(setEditorLoading(true));
    const editorID = yield select(selectEditorID);
    const userEmail = yield select(selectUserEmail);

    const currentStep = yield select(selectCurrentStep);
    // title and slug
    if (currentStep === 1) {
      const titleAndSlugValid = yield select(selectIsTitleAndSlugValid);

      if (titleAndSlugValid) {
        yield put(
          addLogEntry({
            user: userEmail,
            action: "setTitleAndSlug",
            poemID: editorID,
          })
        );
        yield put(nextStepSuccess(currentStep + 1));
      } else {
        yield put(nextStepFail("Cim tul rovid vagy Slug mar letezik"));
      }
      // thumbnails
    } else if (currentStep === 2) {
      const thumbnailOneValid = yield select(selectIsThumbnailOneValid);
      const thumbnailTwoValid = yield select(selectIsThumbnailTwoValid);

      if (thumbnailOneValid && thumbnailTwoValid) {
        yield put(
          addLogEntry({
            user: userEmail,
            action: "setThumbnails",
            poemID: editorID,
          })
        );
        yield put(nextStepSuccess(currentStep + 1));
      } else {
        yield put(
          nextStepFail(
            "Kerlek ellenorizd hogy feltoltotted-e mindket boritokepet"
          )
        );
      }
      // Verse
    } else if (currentStep > 2) {
      const currentVerseValid = yield select(selectIsCurrentVerseTextValid);
      if (currentVerseValid) {
        yield put(createVerseStart());
        const { success, failure } = yield race({
          success: take(EditorActionTypes.CREATE_VERSE_SUCCESS),
          failure: take(EditorActionTypes.CREATE_VERSE_FAIL),
        });
        if (success) {
          yield put(
            addLogEntry({
              user: userEmail,
              action: `set${currentStep - 2}.Verse`,
              poemID: editorID,
            })
          );

          yield put(setCurrentVerseTextValid(false));
          yield put(setVerseImageAltValid(false));
          yield put(nextStepSuccess(currentStep + 1));
        } else {
          yield put(nextStepFail("Nem Sikerult uj versreszletet letrehozni"));
        }
      } else {
        yield put(nextStepFail("Versreszlet nem lehet ures"));
      }
    } else {
      yield put(
        addLogEntry({
          user: userEmail,
          action: "currentStepIsZeroOrNegative",
          poemID: editorID,
        })
      );
      yield put(nextStepFail("negativ lepes szamlalo"));
    }
  } catch (e) {
    const editorID = yield select(selectEditorID);
    const userEmail = yield select(selectUserEmail);
    yield put(
      addLogEntry({
        user: userEmail,
        action: "errorOnNextStep",
        poemID: editorID,
      })
    );
    yield put(nextStepFail(e.message));
  }
}

export function* goToPreviousFormStep(action) {
  try {
    yield put(setEditorLoading(true));

    const editorID = yield select(selectEditorID);
    const userEmail = yield select(selectUserEmail);

    const currentStep = yield select(selectCurrentStep);
    // title and slug
    if (currentStep === 1) {
      yield put(
        addLogEntry({
          user: userEmail,
          action: "inValidStepFromStep1",
          poemID: editorID,
        })
      );
      yield put(prevStepFail("Cim lepesnel nem lehet vissza menni"));

      // thumbnails
    } else if (currentStep === 2) {
      yield put(
        addLogEntry({
          user: userEmail,
          action: "NotAllowedStepFromStep2",
          poemID: editorID,
        })
      );
      yield put(prevStepFail("Cim valtoztatas meg nem lehetseges innen"));
      // Verse
    } else if (currentStep === 3) {
      yield put(setVerseImageAltValid(false));
      yield put(prevStepSuccess(currentStep - 1));
    } else if (currentStep > 3) {
      const verseValid = yield select(selectIsCurrentVerseTextValid);
      if (verseValid) {
        yield put(prevStepSuccess(currentStep - 1));
        yield put(setVerseImageAltValid(false));
      } else {
        yield put(setVerseImageAltValid(false));
        yield put(deleteEmptyVerseStart());
        const { success, failure } = yield race({
          success: take(EditorActionTypes.DELETE_EMPTY_VERSE_SUCCESS),
          failure: take(EditorActionTypes.DELETE_EMPTY_VERSE_FAIL),
        });
        if (success) {
          yield put(prevStepSuccess(currentStep - 1));
        } else {
          yield put(
            addLogEntry({
              user: userEmail,
              action: "unableToDeleteEmptyVerseOnPrevStep",
              poemID: editorID,
            })
          );
          yield put(prevStepFail("Nem torlodott az utolso ures vers resz"));
        }
      }
    } else {
      yield put(
        addLogEntry({
          user: userEmail,
          action: "currentStepIsZeroOrNegative",
          poemID: editorID,
        })
      );
      yield put(prevStepFail("negativ lepes szamlalo"));
    }
  } catch (e) {
    const editorID = yield select(selectEditorID);
    const userEmail = yield select(selectUserEmail);
    yield put(
      addLogEntry({
        user: userEmail,
        action: "errorOnPrevStep",
        poemID: editorID,
      })
    );
    yield put(prevStepFail(e.message));
  }
}

export function* setEditorLoadingAfterSteps(action) {
  try {
    yield put(setEditorLoading(false));
  } catch (e) {
    const editorID = yield select(selectEditorID);
    const userEmail = yield select(selectUserEmail);
    yield put(
      addLogEntry({
        user: userEmail,
        action: "errorOnSettingEditorAfterStep",
        poemID: editorID,
      })
    );
  }
}

export function* onSuccessfullSteps() {
  yield takeEvery(
    [EditorActionTypes.NEXT_STEP_SUCCESS, EditorActionTypes.PREV_STEP_SUCCESS],
    setEditorLoadingAfterSteps
  );
}

export function* onGoingToNextFormStep() {
  yield takeLatest([EditorActionTypes.NEXT_STEP_START], goToNextFormStep);
}

export function* onGoingToPreviousFormStep() {
  yield takeLatest([EditorActionTypes.PREV_STEP_START], goToPreviousFormStep);
}

// TITLE AND SLUG

export function* setTitleAndSlug(action) {
  try {
    yield put(setTitleAndSlugSuccess(action.payload));
  } catch (e) {
    const editorID = yield select(selectEditorID);
    const userEmail = yield select(selectUserEmail);
    yield put(
      addLogEntry({
        user: userEmail,
        action: "errorOnSettingTitleAndSlug",
        poemID: editorID,
      })
    );
  }
}

export function* searchSlug(action) {
  try {
    if (action.payload.slug.length < 5) {
      yield put(setTitleAndSlugValid(false));
    } else {
      const { success, failure } = yield race({
        success: fetchCurrentPoemFromFirestore(action.payload.slug),
        failure: delay(3000),
      });
      if (success.id) {
        yield put(setTitleAndSlugValid(false));
      } else {
        yield put(setTitleAndSlugValid(true));
      }
    }
  } catch (e) {
    const editorID = yield select(selectEditorID);
    const userEmail = yield select(selectUserEmail);
    yield put(
      addLogEntry({
        user: userEmail,
        action: "errorOnSlugSearch",
        poemID: editorID,
      })
    );
  }
}

export function* onSettingTitleAndSlug() {
  yield takeLatest(
    [EditorActionTypes.SET_TITLE_AND_SLUG_START],
    setTitleAndSlug
  );
}
export function* onSlugSearch() {
  yield takeLatest([EditorActionTypes.CHECK_TITLE_AND_SLUG_VALID], searchSlug);
}

// VERSE

export function* createNewVerse(action) {
  try {
    const currentStep = yield select(selectCurrentStep);

    const verseList = yield select(selectVerseList);
    const newVerseList = yield addNewVerseToVerseList(
      verseList,
      currentStep - 1
    );
    yield put(setVerseWEBPImageValid(false));
    yield put(setVerseJPGImageValid(false));
    yield put(createVerseSuccess(newVerseList));
  } catch (e) {
    const editorID = yield select(selectEditorID);
    const userEmail = yield select(selectUserEmail);
    yield put(
      addLogEntry({
        user: userEmail,
        action: "errorOnNewVerseCreation",
        poemID: editorID,
      })
    );
    yield put(createVerseFail(e.message));
  }
}

export function* deleteLastEmptyVerse(action) {
  try {
    const currentStep = yield select(selectCurrentStep);

    const verseList = yield select(selectVerseList);
    const newVerseList = yield removeLastVerseFromVerseList(
      verseList,
      currentStep - 2
    );
    yield put(deleteEmptyVerseSuccess(newVerseList));
  } catch (e) {
    const editorID = yield select(selectEditorID);
    const userEmail = yield select(selectUserEmail);
    yield put(
      addLogEntry({
        user: userEmail,
        action: "errorOnEmptyVerseDeletion",
        poemID: editorID,
      })
    );
    yield put(deleteEmptyVerseFail(e.message));
  }
}

export function* onVerseCreation() {
  yield takeLatest([EditorActionTypes.CREATE_VERSE_START], createNewVerse);
}

export function* onEmptyVerseDeletion() {
  yield takeLatest(
    [EditorActionTypes.DELETE_EMPTY_VERSE_START],
    deleteLastEmptyVerse
  );
}

// VERSE TEXT

export function* checkCurrentVerseTextValid(action) {
  try {
    if (action.payload.verseText.length > 0) {
      yield put(setCurrentVerseTextValid(true));
    } else {
      yield put(setCurrentVerseTextValid(false));
    }
  } catch (e) {
    const editorID = yield select(selectEditorID);
    const userEmail = yield select(selectUserEmail);
    yield put(
      addLogEntry({
        user: userEmail,
        action: "errorOnCheckingVerseTextValid",
        poemID: editorID,
      })
    );
  }
}
export function* onVerseTextChange() {
  yield takeLatest(
    [
      EditorActionTypes.SET_CURRENT_VERSE_TEXT,
      EditorActionTypes.CHECK_CURRENT_VERSE_TEXT_ON_LOAD,
    ],
    checkCurrentVerseTextValid
  );
}

// SYNC CHANGES TO FIRESTORE

export function* syncEditorChangesToFirestore(action) {
  try {
    yield put(syncChangesToFirebaseStart());
    const editor = yield select(selectEditor);
    const { success, failure } = yield race({
      success: call(uploadEditorStateToFireStore, editor),
      failure: delay(3000),
    });
    if (!failure) {
      const editorID = yield select(selectEditorID);
      const userEmail = yield select(selectUserEmail);
      yield put(
        addLogEntry({
          user: userEmail,
          action: "syncEditorChanges",
          poemID: editorID,
        })
      );
      yield put(syncChangesToFirebaseSuccess());
    }
  } catch (e) {
    const editorID = yield select(selectEditorID);
    const userEmail = yield select(selectUserEmail);
    yield put(
      addLogEntry({
        user: userEmail,
        action: "errorOnSyncEditorChanges",
        poemID: editorID,
      })
    );
    yield put(syncChangesToFirebaseFail(e.message));
  }
}

export function* onSuccessfulSteps() {
  yield takeEvery(
    [EditorActionTypes.NEXT_STEP_SUCCESS, EditorActionTypes.PREV_STEP_SUCCESS],
    syncEditorChangesToFirestore
  );
}
// UPLOAD EDITED POEM TO POEMLIST

export function* uploadPoemToPoemListInFirestore(action) {
  try {
    yield put(setEditorLoading(true));
    const editor = yield select(selectEditor);
    const userEmail = yield select(selectUserEmail);
    const { success, failure } = yield race({
      success: saveEditedPoemInFirestore(editor, userEmail),
      failure: delay(3000),
    });
    if (!failure) {
      yield put(uploadPoemToPoemListInFirestoreSuccess());
      yield put(clearDraftStart());
      yield put(setEditorLoading(false));
    } else {
      yield put(
        uploadPoemToPoemListInFirestoreFail(
          "Nem lehetseges feltolteni a verset most, kerlek probald ujra"
        )
      );
      yield put(setEditorLoading(false));
    }
  } catch (e) {
    const editorID = yield select(selectEditorID);
    const userEmail = yield select(selectUserEmail);
    yield put(
      addLogEntry({
        user: userEmail,
        action: "errorOnUploadingPoemToFirebase",
        poemID: editorID,
      })
    );
    yield put(uploadPoemToPoemListInFirestoreFail(e.message));
  }
}

export function* onUploadingPoemToFirebase() {
  yield takeLatest(
    [EditorActionTypes.UPLOAD_EDITED_POEM_TO_POEMLIST_START],
    uploadPoemToPoemListInFirestore
  );
}

// CLEAR DRAFT

export function* clearDraft(action) {
  try {
    yield put(clearDraftSuccess());
  } catch (e) {
    const editorID = yield select(selectEditorID);
    const userEmail = yield select(selectUserEmail);
    yield put(
      addLogEntry({
        user: userEmail,
        action: "errorClearingDraft",
        poemID: editorID,
      })
    );
    yield put(clearDraftFail(e.message));
  }
}

export function* onClearingDraft() {
  yield takeLatest([EditorActionTypes.CLEAR_DRAFT_START], clearDraft);
}

// EDITOR SAGAS
export function* editorSagas() {
  yield all([
    call(onStartingEditor),
    call(onCheckingEditorIsReadyToProceed),
    call(onSettingTitleAndSlug),
    call(onSlugSearch),
    call(onGoingToNextFormStep),
    call(onGoingToPreviousFormStep),
    call(onSuccessfullSteps),
    call(onVerseCreation),
    call(onEmptyVerseDeletion),
    call(onVerseTextChange),
    call(onSuccessfulSteps),
    call(onUploadingPoemToFirebase),
    call(onClearingDraft),
  ]);
}
