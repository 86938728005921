import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { Card, Button, Alert, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import styled from "styled-components";
import { layoutBreakpoints } from "../../global-styles/layoutBreakpoints";
import { selectCurrentTheme } from "../../redux/theme/theme.selectors";
import { selectIsLoading } from "../../redux/poem/poem.selectors";
import {
  deletePoemStart,
  markPoemAsVerifiedStart,
  publishPoemStart,
  unPublishPoemStart,
} from "../../redux/poem/poem.actions";
import SpinnerOverlay from "../SpinnerOverlay/SpinnerOverlay";
const FlexContainer = styled.div`
  display: flex;
`;

export const CardImage = styled.picture`
  @media (max-width: ${layoutBreakpoints.xs}) {
    justify-self: start;
    width: 100%;
    height: auto;
    align-self: end;
  }

  height: 182.39px;
  width: 182.39px;
`;

const PoemsListItem = ({
  poem,
  currentTheme,
  isLoading,
  deletePoem,
  verifyPoem,
  publishPoem,
  unPublishPoem,
}) => {
  const [showAlert, setShowAlert] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showMarkAsVerifiedAlert, setShowMarkAsVerifiedAlert] = useState(false);
  const [showPublishPoemAlert, setShowPublishPoemAlert] = useState(false);

  const handleDeleteOptionClick = () => {
    setShowAlert(true);
    setShowDeleteAlert(true);
  };

  const handleDeleteClick = () => {
    deletePoem(poem);
    setShowAlert(false);
    setShowDeleteAlert(false);
  };
  const handleMarkAsVerifiedOptionClick = () => {
    setShowAlert(true);
    setShowMarkAsVerifiedAlert(true);
  };

  const handleMarkAsVerifiedClick = () => {
    verifyPoem(poem);
    setShowAlert(false);
    setShowMarkAsVerifiedAlert(false);
  };

  const handlePublishPoemOptionClick = () => {
    setShowAlert(true);
    setShowPublishPoemAlert(true);
  };

  const handlePublishPoemClick = () => {
    if (!poem.isPublished) {
      publishPoem(poem);
    } else if (poem.isPublished) {
      unPublishPoem(poem);
    }
    setShowAlert(false);
    setShowPublishPoemAlert(false);
  };
  return (
    <Card
      className={`my-4 ${
        currentTheme.name === "lightTheme" ? "bg-ligth" : "bg-dark"
      }`}
      id={poem.id}
    >
      <Card.Header as="h5" className="my-2 text-center">
        {poem.title}
      </Card.Header>
      <FlexContainer className="my-3">
        <Card.Body>
          {showAlert ? (
            <Fragment>
              {isLoading ? (
                <SpinnerOverlay spinnerText="pillanat ..." />
              ) : showDeleteAlert ? (
                <Alert variant="danger" className="text-center">
                  <Alert.Heading>
                    Biztos törölni akarod ezt a mesét?
                  </Alert.Heading>
                  <hr />
                  <Row className="my-3">
                    <em>Ez a folyamat nem visszafordítható!</em>
                  </Row>
                  <hr />
                  <Row>
                    <Col>
                      <div className="d-flex justify-content-start">
                        <Button
                          onClick={() => setShowAlert(false)}
                          variant="warning"
                          className="d-flex justify-content-start"
                          size="lg"
                        >
                          Vissza
                        </Button>
                      </div>
                    </Col>
                    <Col>
                      <div className="d-flex justify-content-end">
                        <Button
                          onClick={handleDeleteClick}
                          variant="danger"
                          className="d-flex justify-content-end"
                          size="lg"
                        >
                          Mese törlése
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Alert>
              ) : showMarkAsVerifiedAlert ? (
                <Alert variant="warning" className="text-center">
                  <Alert.Heading>Mese ellenőrízve?</Alert.Heading>
                  <hr />
                  <Row className="my-3">
                    <em>
                      Ez a folyamat csak űgy csinálható vissza ha a mesét később
                      megnyitod szerkeztésre. Még nem lesz látható a
                      nagyközönség számára.
                    </em>
                  </Row>
                  <hr />
                  <Row>
                    <Col>
                      <div className="d-flex justify-content-start">
                        <Button
                          onClick={() => setShowAlert(false)}
                          variant="warning"
                          className="d-flex justify-content-start"
                          size="lg"
                        >
                          Vissza
                        </Button>
                      </div>
                    </Col>
                    <Col>
                      <div className="d-flex justify-content-end">
                        <Button
                          onClick={handleMarkAsVerifiedClick}
                          variant="primary"
                          className="d-flex justify-content-end"
                          size="lg"
                        >
                          A mese rendben:)
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Alert>
              ) : showPublishPoemAlert ? (
                <Alert
                  variant={poem.isPublished ? "danger" : "warning"}
                  className="text-center"
                >
                  <Alert.Heading>
                    {poem.isPublished
                      ? "Biztosan visszahívod a mesét?"
                      : "Publikálod a mesét?"}
                  </Alert.Heading>
                  <hr />
                  <Row className="my-3">
                    <em>
                      {poem.isPublished
                        ? "Amelyik készülékre már letöltötték a verset, ott még látszódni fog a következő frisítésig."
                        : "Ez a folyamat részben visszafordítható, viszont ahol megnyitják a weboldalt mielőtt visszahívod, azon a keszüléken le lesz töltve ez a verzió."}
                    </em>
                  </Row>
                  <hr />
                  <Row>
                    <Col>
                      <div className="d-flex justify-content-start">
                        <Button
                          onClick={() => setShowAlert(false)}
                          variant="warning"
                          className="d-flex justify-content-start"
                          size="lg"
                        >
                          Vissza
                        </Button>
                      </div>
                    </Col>
                    <Col>
                      <div className="d-flex justify-content-end">
                        <Button
                          onClick={handlePublishPoemClick}
                          variant={poem.isPublished ? "danger" : "primary"}
                          className="d-flex justify-content-end"
                          size="lg"
                        >
                          {poem.isPublished ? "Vsszahívom:(" : "Publikálom:)"}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Alert>
              ) : (
                <h1>other options</h1>
              )}
            </Fragment>
          ) : (
            <Row>
              <Col md={8} lg={8}>
                <Row>
                  <Col md={6} className="my-1 ">
                    <Card.Text>Készítette: {poem.createdBy}</Card.Text>
                  </Col>
                  <Col md={6} className="my-1 ">
                    <Card.Text>
                      Készült: {poem.createdOn.split("T")[0]}
                    </Card.Text>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} className="my-1 ">
                    <Card.Text>
                      Ellenőrízte:{" "}
                      {poem.isVerified ? poem.verifiedBy : "Még senki"}
                    </Card.Text>
                  </Col>
                  <Col md={6} className="my-1 ">
                    <Card.Text>
                      Publikálta:{" "}
                      {poem.isPublished ? poem.publishedBy : "Még senki"}
                    </Card.Text>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} className="my-1 ">
                    <Card.Text>
                      Éppen Szerkeszti:{" "}
                      {poem.isCheckedOut ? poem.checkedOutBy : "Most senki"}
                    </Card.Text>
                  </Col>
                </Row>
              </Col>
              <Col md={4} lg={4} className="d-flex justify-content-center">
                <CardImage>
                  <source
                    srcSet={`https://firebasestorage.googleapis.com/v0/b/versesmesek-706db.appspot.com/o/static%2Fimages%2F${poem.slug}%2F${poem.thumbnail}.webp?alt=media`}
                    type="image/webp"
                  />
                  <img
                    src={`this.src=https://firebasestorage.googleapis.com/v0/b/versesmesek-706db.appspot.com/o/static%2Fimages%2F${poem.slug}%2F${poem.thumbnail}.jpg?alt=media`}
                    alt={`${poem.title}`}
                    type="image/jpg"
                  />
                </CardImage>
              </Col>
            </Row>
          )}
        </Card.Body>
      </FlexContainer>
      <Card.Footer>
        {!showAlert && (
          <Row>
            <Col md={6} lg={3}>
              {" "}
              <Link to={`${poem.slug}`} key={poem.id}>
                <Button size="lg" variant="primary" className="my-3 mx-1 w-100">
                  Megnyitás
                </Button>
              </Link>
            </Col>
            <Col md={6} lg={3}>
              {" "}
              <Button
                size="lg"
                variant="warning"
                className="my-3 mx-1 w-100"
                disabled
              >
                Szerkeztés
              </Button>
            </Col>
            <Col md={6} lg={3}>
              {!poem.isVerified && !poem.isPublished ? (
                <Button
                  size="lg"
                  variant="secondary"
                  className="my-3 mx-1 w-100"
                  onClick={handleMarkAsVerifiedOptionClick}
                >
                  Ellenőriztem:)
                </Button>
              ) : !poem.isPublished ? (
                <Button
                  size="lg"
                  variant="success"
                  className="my-3 mx-1 w-100"
                  onClick={handlePublishPoemOptionClick}
                >
                  Publikálás
                </Button>
              ) : (
                <Button
                  size="lg"
                  variant="danger"
                  className="my-3 mx-1 w-100"
                  onClick={handlePublishPoemOptionClick}
                >
                  Visszahívom
                </Button>
              )}
            </Col>
            <Col md={6} lg={3}>
              <Button
                size="lg"
                variant="danger"
                className="my-3 mx-1 w-100"
                onClick={handleDeleteOptionClick}
              >
                Törlés
              </Button>
            </Col>
          </Row>
        )}
      </Card.Footer>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  currentTheme: selectCurrentTheme(state),
  isLoading: selectIsLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  deletePoem: (poem) => dispatch(deletePoemStart(poem)),
  verifyPoem: (poem) => dispatch(markPoemAsVerifiedStart(poem)),
  publishPoem: (poem) => dispatch(publishPoemStart(poem)),
  unPublishPoem: (poem) => dispatch(unPublishPoemStart(poem)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PoemsListItem);
