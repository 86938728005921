import React, { useEffect } from "react";
import { connect } from "react-redux";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import {
  StyledLink,
  ThemeSelectorContainer,
  ThemeSymbol,
  ThemeToggleButton,
} from "./Header.styles";

import { switchThemesStart } from "../../redux/theme/theme.actions";
import { selectCurrentTheme } from "../../redux/theme/theme.selectors";
import { selectIsUserAuthenticated } from "../../redux/user/user.selectors";
import { signOutStart } from "../../redux/user/user.actions";
import { Link, NavLink, useNavigate } from "react-router-dom";

const Header = ({ currentTheme, switchThemes, isAuthenticated, signOut }) => {
  const navigate = useNavigate();
  const handleThemeChange = () => {
    switchThemes(currentTheme.name);
  };
  const handleSignOutClick = () => {
    signOut();
    navigate("/");
  };
  return (
    <Navbar bg="navBG" expand="sm" className="px-1 text-center" fixed="top">
      <Container>
        <Nav className="my-2 text-uppercase ">
          <StyledLink to="/">Verses Mesék Admin</StyledLink>
        </Nav>

        <Navbar.Toggle />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="me-auto my-2 my-lg-0">
            <NavLink to="/mesek" className="mx-2">
              Mesék
            </NavLink>
            <NavLink to="/uj-mese" className="mx-2">
              Új mese
            </NavLink>
          </Nav>
          <Nav>
            {isAuthenticated && (
              <Button
                className="mx-2"
                variant="outline-secondary"
                onClick={handleSignOutClick}
              >
                Kijelentkezés
              </Button>
            )}
          </Nav>
          <ThemeSelectorContainer onClick={handleThemeChange}>
            {currentTheme.name === "lightTheme" ? (
              <React.Fragment>
                <ThemeSymbol>🌞</ThemeSymbol>
                <ThemeToggleButton />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <ThemeToggleButton />
                <ThemeSymbol>🌛</ThemeSymbol>
              </React.Fragment>
            )}
          </ThemeSelectorContainer>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: selectIsUserAuthenticated(state),
  currentTheme: selectCurrentTheme(state),
});

const mapDispatchToProps = (dispatch) => ({
  signOut: () => dispatch(signOutStart()),
  switchThemes: () => dispatch(switchThemesStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
