import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import userReducer from "./user/user.reducer";
import poemsReducer from "./poems/poems.reducer";
import themeReducer from "./theme/theme.reducer";
import editorReducer from "./editor/editor.reducer";
import poemReducer from "./poem/poem.reducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["theme", "editor"],
};

const rootReducer = combineReducers({
  user: userReducer,
  poems: poemsReducer,
  theme: themeReducer,
  editor: editorReducer,
  poem: poemReducer,
});

export default persistReducer(persistConfig, rootReducer);
