import styled from "styled-components";

import Container from "react-bootstrap/Container";

export const PoemContainer = styled(Container)`
  max-width: 900px;
  min-height: 90vh;
  margin-top: 100px;
`;

export const BackButton = styled.div`
  width: 100px;
  max-width: 100px;
  background-color: ${(props) => props.theme.primaryBackgroundColor};
  border-color: ${(props) => props.theme.primaryColor} !important;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  :hover {
    background-color: ${(props) => props.theme.boxColor3};
    border-color: ${(props) => props.theme.boxColor3} !important;
    cursor: pointer;
    a {
      color: ${(props) => props.theme.primaryColor};
    }
  }
`;
export const StyledText = styled.p`
  white-space: pre;
`;

export const GridContainer = styled(Container)`
  display: grid;
  grid: repeat(30, 1fr) / repeat(22, 1fr);
  justify-content: center;
  @media (min-width: 310px) {
    grid: repeat(30, 14px) / repeat(22, 15px);
  }
`;
export const TopLeftColoredBox = styled.div`
  grid-row: 1 /17;
  grid-column: 1 / 12;
  background-color: ${(props) => props.theme.boxColor3};
`;
export const BottomRightColoredBox = styled.div`
  grid-row: 15 /31;
  grid-column: 12 / 23;
  background-color: ${(props) => props.theme.boxColor1};
`;
export const MiddleContainer = styled.div`
  grid-row: 3 / 29;
  grid-column: 3 / 21;
  z-index: 3;
  border-top: solid 13px ${(props) => props.theme.borderColor};
  border-right: solid 10px ${(props) => props.theme.borderColor};
  border-bottom: solid 13px ${(props) => props.theme.borderColor};
  border-left: solid 10px ${(props) => props.theme.borderColor};
`;

export const VerseImage = styled.picture`
  max-width: 100%;
  height: auto;
  @media (min-width: 310px) {
    height: 340px;
    width: 250px;
  }
`;
