import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Outlet } from "react-router-dom";
import Header from "../../components/Header/Header";
import { selectIsUserAuthenticated } from "../../redux/user/user.selectors";
import SignInPage from "../SignInPage/SignInPage";
import styled from "styled-components";

const Divider = styled.div`
  height: 100px;
`;

const Navigation = ({ isAuthenticated }) => {
  return (
    <Fragment>
      <Header />
      <Divider />
      {isAuthenticated ? <Outlet /> : <SignInPage />}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: selectIsUserAuthenticated(state),
});

export default connect(mapStateToProps)(Navigation);
