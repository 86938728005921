const EditorActionTypes = {
  START_EDITOR_START: "START_EDITOR_START",
  START_EDITOR_SUCCESS: "START_EDITOR_SUCCESS",
  START_EDITOR_FAIL: "START_EDITOR_FAIL",

  CHECK_IF_READY_TO_PROCEED_START: "CHECK_IF_READY_TO_PROCEED_START",
  CHECK_IF_READY_TO_PROCEED_SUCCESS: "CHECK_IF_READY_TO_PROCEED_SUCCESS",
  CHECK_IF_READY_TO_PROCEED_FAIL: "CHECK_IF_READY_TO_PROCEED_FAIL",
  SET_READY_TO_PROCEED: "SET_READY_TO_PROCEED",

  NEXT_STEP_START: "NEXT_STEP_START",
  NEXT_STEP_SUCCESS: "NEXT_STEP_SUCCESS",
  NEXT_STEP_FAIL: "NEXT_STEP_FAIL",

  PREV_STEP_START: "PREV_STEP_START",
  PREV_STEP_SUCCESS: "PREV_STEP_SUCCESS",
  PREV_STEP_FAIL: "PREV_STEP_FAIL",

  SET_EDITOR_LOADING: "SET_EDITOR_LOADING",

  CREATE_DRAFT: "CREATE_DRAFT",

  CLEAR_DRAFT_START: "CLEAR_DRAFT_START",
  CLEAR_DRAFT_SUCCESS: "CLEAR_DRAFT_SUCCESS",
  CLEAR_DRAFT_FAIL: "CLEAR_DRAFT_FAIL",

  SET_TITLE_AND_SLUG_START: "SET_TITLE_AND_SLUG_START",
  SET_TITLE_AND_SLUG_SUCCESS: "SET_TITLE_AND_SLUG_SUCCESS",
  CHECK_TITLE_AND_SLUG_VALID: "CHECK_TITLE_AND_SLUG_VALID",

  SET_THUMBNAIL: "SET_THUMBNAIL",

  CREATE_VERSE_START: "CREATE_VERSE_START",
  CREATE_VERSE_SUCCESS: "CREATE_VERSE_SUCCESS",
  CREATE_VERSE_FAIL: "CREATE_VERSE_FAIL",

  DELETE_EMPTY_VERSE_START: "DELETE_EMPTY_VERSE_START",
  DELETE_EMPTY_VERSE_SUCCESS: "DELETE_EMPTY_VERSE_SUCCESS",
  DELETE_EMPTY_VERSE_FAIL: "DELETE_EMPTY_VERSE_FAIL",

  SET_CURRENT_VERSE_TEXT: "SET_CURRENT_VERSE_TEXT",
  CHECK_CURRENT_VERSE_TEXT_ON_LOAD: "CHECK_CURRENT_VERSE_TEXT_ON_LOAD",
  SET_VERSE_IMAGE_ALT: "SET_VERSE_IMAGE_ALT",
  SET_VERSE_IMAGE_UPLOADED: "SET_VERSE_IMAGE_UPLOADED",

  ADD_LOG_ENTRY: "ADD_LOG_ENTRY",

  SET_TITLE_AND_SLUG_VALID: "SET_TITLE_AND_SLUG_VALID",
  SET_THUMBNAIL_ONE_VALID: "SET_THUMBNAIL_ONE_VALID",
  SET_THUMBNAIL_TWO_VALID: "SET_THUMBNAIL_TWO_VALID",
  SET_CURRENT_VERSE_TEXT_VALID: "SET_CURRENT_VERSE_TEXT_VALID",
  SET_VERSE_IMAGE_ALT_VALID: "SET_VERSE_IMAGE_ALT_VALID",
  SET_VERSE_WEBP_IMAGE_VALID: "SET_VERSE_WEBP_IMAGE_VALID",
  SET_VERSE_JPG_IMAGE_VALID: "SET_VERSE_JPG_IMAGE_VALID",

  SYNC_CHANGES_TO_FIREBASE_START: "SYNC_CHANGES_TO_FIREBASE_START",
  SYNC_CHANGES_TO_FIREBASE_SUCCESS: "SYNC_CHANGES_TO_FIREBASE_SUCCESS",
  SYNC_CHANGES_TO_FIREBASE_FAIL: "SYNC_CHANGES_TO_FIREBASE_FAIL",

  UPLOAD_EDITED_POEM_TO_POEMLIST_START: "UPLOAD_EDITED_POEM_TO_POEMLIST_START",
  UPLOAD_EDITED_POEM_TO_POEMLIST_SUCCESS:
    "UPLOAD_EDITED_POEM_TO_POEMLIST_SUCCESS",
  UPLOAD_EDITED_POEM_TO_POEMLIST_FAIL: "UPLOAD_EDITED_POEM_TO_POEMLIST_FAIL",

  SET_FORM_SUBMITTED: "SET_FORM_SUBMITTED",

  SET_ERROR_ENTRIES: "SET_ERROR_ENTRIES",
};

export default EditorActionTypes;
