import {
  all,
  call,
  put,
  takeEvery,
  race,
  delay,
  take,
  select,
} from "redux-saga/effects";

import PoemsActionTypes from "./poems.types";
import {
  fetchPoemsFail,
  fetchPoemsStart,
  fetchPoemsSuccess,
  loadPoemsFail,
  loadPoemsSuccess,
} from "./poems.actions";
import { fetchPoemsFromFirestore } from "../../utils/firebase/firebase.utils";
import EditorActionTypes from "../editor/editor.types";
import PoemActionTypes from "../poem/poem.types";

export const selectAllPoems = (state) => state.poems.allPoems;

export const selectUserIsAuthenticated = (state) => state.user.isAuthenticated;

export function* loadPoems(action) {
  try {
    const allPoems = yield select(selectAllPoems);

    if (!allPoems.length) {
      yield put(fetchPoemsStart());
      yield take(PoemsActionTypes.FETCH_POEMS_SUCCESS);
    }
    yield put(loadPoemsSuccess());
  } catch (e) {
    yield put(loadPoemsFail(e.message));
  }
}

export function* fetchPoems(action) {
  try {
    const { fetchedPoems, timeout } = yield race({
      fetchedPoems: fetchPoemsFromFirestore(),
      timeout: delay(1000),
    });
    if (fetchedPoems) {
      yield put(fetchPoemsSuccess(fetchedPoems));
    } else if (timeout) {
      yield put(fetchPoemsFail("unable to fetch poems from firestore"));
    }
  } catch (e) {
    yield put(fetchPoemsFail(e.message));
  }
}

export function* onLoadingPoems() {
  yield takeEvery([PoemsActionTypes.LOAD_POEMS_START], loadPoems);
}

export function* onFetchingPoems() {
  yield takeEvery(
    [
      PoemsActionTypes.FETCH_POEMS_START,
      EditorActionTypes.UPLOAD_EDITED_POEM_TO_POEMLIST_SUCCESS,
      PoemActionTypes.DELETE_POEM_SUCCESS,
      PoemActionTypes.MARK_POEM_AS_VERIFIED_SUCCESS,
      PoemActionTypes.PUBLISH_POEM_SUCCESS,
      PoemActionTypes.UN_PUBLISH_POEM_SUCCESS,
    ],
    fetchPoems
  );
}

export function* poemsSagas() {
  yield all([call(onLoadingPoems), call(onFetchingPoems)]);
}
