import { createSelector } from "reselect";

export const selectUser = (state) => state.user;

export const selectIsUserAuthenticated = createSelector(
  [selectUser],
  (user) => user.isAuthenticated
);

export const selectUserFirstName = createSelector(
  [selectUser],
  (user) => user.firstName
);
