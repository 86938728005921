import EditorActionTypes from "./editor.types";
import {
  createEditorID,
  setVerseImageAltInVerseList,
  setVerseImageUploadedInVerseList,
  setCurrentVerseTextInVerseList,
} from "./editor.utils";

const INITIAL_STATE = {
  editorID: "",
  currentStep: 1,
  editorLoading: true,
  draft: true,
  checked: false,
  published: false,
  createdOn: "",
  title: "",
  slug: "",
  thumbnail: "",
  verseList: [
    {
      id: 1,
      verse: "",
      verseImage: "",
      verseImageUploaded: false,
      verseImageAlt: "",
    },
  ],
  editorLogs: [],
  errorMessage: "",
  editorReady: false,
  isReadyToProceed: false,
  isTitleAndSlugValid: false,
  isThumbnailOneValid: false,
  isThumbnailTwoValid: false,
  isCurrentVerseTextValid: false,
  isCurrentVerseImageAltValid: false,
  isCurrentVerseImageWEBPValid: false,
  isCurrentVerseImageJPGValid: false,
};

const editorReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    //
    // EDITOR
    //
    case EditorActionTypes.START_EDITOR_START:
      return {
        ...state,
        editorReady: false,
        editorLoading: true,
      };
    case EditorActionTypes.START_EDITOR_SUCCESS:
      return {
        ...state,
        editorReady: true,
        editorLoading: false,
      };
    case EditorActionTypes.START_EDITOR_FAIL:
      return {
        ...state,
        editorReady: false,
        errorMessage: action.payload,
      };
    case EditorActionTypes.SET_EDITOR_LOADING:
      return {
        ...state,
        editorLoading: action.payload,
      };
    case EditorActionTypes.CHECK_IF_READY_TO_PROCEED_START:
      return {
        ...state,
      };
    case EditorActionTypes.CHECK_IF_READY_TO_PROCEED_SUCCESS:
      return {
        ...state,
      };
    case EditorActionTypes.CHECK_IF_READY_TO_PROCEED_FAIL:
      return {
        ...state,
      };
    case EditorActionTypes.SET_READY_TO_PROCEED:
      return {
        ...state,
        isReadyToProceed: action.payload,
      };
    //
    // DRAFT
    //
    case EditorActionTypes.CREATE_DRAFT:
      return {
        ...state,
        editorID: createEditorID(), // Remove all non-word chars,
        createdOn: new Date().toISOString(),
      };

    case EditorActionTypes.CLEAR_DRAFT_START:
      return {
        ...state,
      };
    case EditorActionTypes.CLEAR_DRAFT_SUCCESS:
      return {
        editorID: "",
        currentStep: 1,
        editorLoading: true,
        draft: true,
        checked: false,
        published: false,
        createdOn: "",
        title: "",
        slug: "",
        thumbnail: "",
        verseList: [
          {
            id: 1,
            verse: "",
            verseImage: "",
            verseImageUploaded: false,
            verseImageAlt: "",
          },
        ],
        editorLogs: [],
        errorMessage: "",
        editorReady: false,
        isReadyToProceed: false,
        isTitleAndSlugValid: false,
        isThumbnailOneValid: false,
        isThumbnailTwoValid: false,
        isCurrentVerseTextValid: false,
        isCurrentVerseImageAltValid: false,
        isCurrentVerseImageWEBPValid: false,
        isCurrentVerseImageJPGValid: false,
      };
    case EditorActionTypes.CLEAR_DRAFT_FAIL:
      return {
        ...state,
        errorMessage: action.payload,
      };
    //
    // FORM STEPS
    //
    case EditorActionTypes.NEXT_STEP_START:
      return {
        ...state,
      };
    case EditorActionTypes.NEXT_STEP_SUCCESS:
      return {
        ...state,
        currentStep: action.payload,
      };
    case EditorActionTypes.NEXT_STEP_FAIL:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case EditorActionTypes.PREV_STEP_START:
      return {
        ...state,
      };
    case EditorActionTypes.PREV_STEP_SUCCESS:
      return {
        ...state,
        currentStep: action.payload,
      };
    case EditorActionTypes.PREV_STEP_FAIL:
      return {
        ...state,
        errorMessage: action.payload,
      };

    //
    // TITLE AND SLUG
    //
    case EditorActionTypes.SET_TITLE_AND_SLUG_START:
      return {
        ...state,
      };
    case EditorActionTypes.SET_TITLE_AND_SLUG_SUCCESS:
      return {
        ...state,
        title: action.payload.title,
        slug: action.payload.slug,
      };
    case EditorActionTypes.CHECK_TITLE_AND_SLUG_VALID:
      return {
        ...state,
        title: action.payload.title,
        slug: action.payload.slug,
      };
    case EditorActionTypes.SET_TITLE_AND_SLUG_VALID:
      return {
        ...state,
        isTitleAndSlugValid: action.payload,
      };
    //
    // THUMBNAIL
    //
    case EditorActionTypes.SET_THUMBNAIL:
      return {
        ...state,
        thumbnail: action.payload,
      };

    case EditorActionTypes.SET_THUMBNAIL_ONE_VALID:
      return {
        ...state,
        isThumbnailOneValid: action.payload,
      };
    case EditorActionTypes.SET_THUMBNAIL_TWO_VALID:
      return {
        ...state,
        isThumbnailTwoValid: action.payload,
      };
    //
    // VERSE
    //
    case EditorActionTypes.CREATE_VERSE_START:
      return {
        ...state,
      };
    case EditorActionTypes.CREATE_VERSE_SUCCESS:
      return {
        ...state,
        verseList: action.payload,
      };
    case EditorActionTypes.CREATE_VERSE_FAIL:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case EditorActionTypes.DELETE_EMPTY_VERSE_START:
      return {
        ...state,
      };
    case EditorActionTypes.DELETE_EMPTY_VERSE_SUCCESS:
      return {
        ...state,
        verseList: action.payload,
      };
    case EditorActionTypes.DELETE_EMPTY_VERSE_FAIL:
      return {
        ...state,
        errorMessage: action.payload,
      };

    //
    // VERSE TEXT
    //
    case EditorActionTypes.SET_CURRENT_VERSE_TEXT:
      return {
        ...state,
        verseList: setCurrentVerseTextInVerseList(
          state.verseList,
          action.payload.id,
          action.payload.verseText
        ),
      };
    case EditorActionTypes.SET_CURRENT_VERSE_TEXT_VALID:
      return {
        ...state,
        isCurrentVerseTextValid: action.payload,
      };
    //
    // VERSE IMAGE
    //
    case EditorActionTypes.SET_VERSE_IMAGE_ALT:
      return {
        ...state,
        verseList: setVerseImageAltInVerseList(
          state.verseList,
          action.payload.id,
          action.payload.imageAlt
        ),
      };
    case EditorActionTypes.SET_VERSE_IMAGE_ALT_VALID:
      return {
        ...state,
        isCurrentVerseImageAltValid: action.payload,
      };
    case EditorActionTypes.SET_VERSE_IMAGE_UPLOADED:
      return {
        ...state,
        verseList: setVerseImageUploadedInVerseList(
          state.verseList,
          action.payload.id,
          action.payload.isUploaded,
          action.payload.verseImage
        ),
      };
    case EditorActionTypes.SET_VERSE_WEBP_IMAGE_VALID:
      return {
        ...state,
        isCurrentVerseImageWEBPValid: action.payload,
      };
    case EditorActionTypes.SET_VERSE_JPG_IMAGE_VALID:
      return {
        ...state,
        isCurrentVerseImageJPGValid: action.payload,
      };
    //
    // LOG
    //
    case EditorActionTypes.ADD_LOG_ENTRY:
      return {
        ...state,
        editorLogs: [
          ...state.editorLogs,
          {
            timestamp: new Date().toISOString(),
            user: action.payload.user,
            action: action.payload.action,
          },
        ],
      };

    //
    // SYNC TO FIREBASE
    //
    case EditorActionTypes.SYNC_CHANGES_TO_FIREBASE_START:
      return {
        ...state,
      };
    case EditorActionTypes.SYNC_CHANGES_TO_FIREBASE_SUCCESS:
      return {
        ...state,
      };
    case EditorActionTypes.SYNC_CHANGES_TO_FIREBASE_FAIL:
      return {
        ...state,
        errorMessage: action.payload,
      };
    //
    // UPLOAD POEM TO FIREBASE
    //
    case EditorActionTypes.UPLOAD_EDITED_POEM_TO_POEMLIST_START:
      return {
        ...state,
      };
    case EditorActionTypes.UPLOAD_EDITED_POEM_TO_POEMLIST_SUCCESS:
      return {
        ...state,
      };
    case EditorActionTypes.UPLOAD_EDITED_POEM_TO_POEMLIST_FAIL:
      return {
        ...state,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};

export default editorReducer;
