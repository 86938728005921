import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { loadPoemsStart } from "../../redux/poems/poems.actions";
import {
  selectDraftPoemsCount,
  selectPublishedPoemsCount,
  selectVerifiedPoemsCount,
} from "../../redux/poems/poems.selectors";
import CounterDashBoardItem from "../CounterDashBoardItem/CounterDashBoardItem";

const DashboardItemsList = ({
  loadPoems,
  draftPoemsCount,
  verifiedPoemsCount,
  publishedPoemsCount,
}) => {
  useEffect(() => {
    loadPoems();
  }, [draftPoemsCount, verifiedPoemsCount, publishedPoemsCount]);

  return (
    <Container>
      <Row>
        <h2>Mesék</h2>
        <Col md={4}>
          <CounterDashBoardItem
            title="Piszkozatok"
            number={draftPoemsCount}
            linkName="Ugrás a Piszkozatokhoz"
            linkUrl="/mesek#piszkozatok"
          />
        </Col>
        <Col md={4}>
          <CounterDashBoardItem
            title="Ellenőrzött"
            number={verifiedPoemsCount}
            linkName="Ugrás az Ellenőrzött mesékre"
            linkUrl="/mesek#ellenőrzött"
          />
        </Col>
        <Col md={4}>
          <CounterDashBoardItem
            title="Publikált"
            number={publishedPoemsCount}
            linkName="Ugrás a Publikált
            mesékhez"
            linkUrl="/mesek#publikált"
          />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  draftPoemsCount: selectDraftPoemsCount(state),
  verifiedPoemsCount: selectVerifiedPoemsCount(state),
  publishedPoemsCount: selectPublishedPoemsCount(state),
});

const mapDispatchToProps = (dispatch) => ({
  loadPoems: () => dispatch(loadPoemsStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardItemsList);
