import React from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import AddPoemForm from "../../components/AddPoemForm/AddPoemForm";
import { selectTitle } from "../../redux/editor/editor.selectors";

const AddPoemPage = ({ title }) => {
  return (
    <Container>
      <h1>Piszkozat {title.length ? ` - ${title}` : ""}</h1>
      <AddPoemForm />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  title: selectTitle(state),
});

export default connect(mapStateToProps)(AddPoemPage);
