import React, { Fragment, useEffect, useState } from "react";
import { Alert, Form, Row, Image } from "react-bootstrap";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  setThumbnail,
  setThumbnailOneValid,
  setThumbnailTwoValid,
  setVerseImageUploaded,
  setVerseJPGImageValid,
  setVerseWEBPImageValid,
} from "../../redux/editor/editor.actions";
import {
  selectIsThumbnailOneValid,
  selectIsThumbnailTwoValid,
  selectSlug,
  selectThumbnail,
  selectVerseList,
} from "../../redux/editor/editor.selectors";
import { uploadPoemImageToFirebase } from "../../utils/firebase/firebase.utils";
import SpinnerOverlay from "../SpinnerOverlay/SpinnerOverlay";

const ImageContainer = styled.div`
  width: ${(props) => (props.thumbnail ? "193px" : "260px")};
  height: ${(props) => (props.thumbnail ? "193px" : "350px")};
  border: 5px solid yellow;
  padding: 0;
  margin: 0;
  img {
    opacity: 0.9;
    z-index: -1;
  }
`;

const ImageFormField = ({
  fileType,
  thumbnailOne,
  thumbnailTwo,
  slug,
  verseID,
  setThumbnailOneValid,
  setThumbnailTwoValid,
  thumbnailOneValid,
  thumbnailTwoValid,
  thumbnailName,
  setThumbnailName,
  setVerseImageUploaded,
  verseList,
  isDisabled,
  setVerseWEBPImageValid,
  setVerseJPGImageValid,
}) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewSRC, setPreviewSRC] = useState("");
  const [imageTypeValid, setImageTypeValid] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadFinished, setUploadFinished] = useState(false);

  useEffect(() => {
    if (verseID !== null) {
      const currentVerse = verseList.find((verse) => verse.id === verseID);
      if (currentVerse !== undefined) {
        if (!currentVerse.verseImageUploaded) {
          setUploadFinished(false);
          setSelectedImage(null);
          setPreviewSRC("");
        } else {
          setUploadFinished(true);
          setVerseWEBPImageValid(true);
          setVerseJPGImageValid(true);
        }
      }
    }
  }, [verseList, verseID]);

  const generateImageName = (fileName, option, fileType) => {
    return `${fileName}${option}.${fileType}`
      .toString() // Cast to string
      .replace("-", "_"); // Replace spaces with -
  };

  const handleImageSelection = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].name.split(".")[1] === fileType.toLowerCase()) {
        setPreviewSRC(URL.createObjectURL(e.target.files[0]));
        setIsUploading(true);
        setUploadFinished(false);
        const image = e.target.files[0];

        setSelectedImage(image);
        const postString =
          thumbnailOne || thumbnailTwo ? "_thumbnail" : verseID.toString();
        const imageName = generateImageName(slug, postString, fileType);
        uploadPoemImageToFirebase(image, slug, imageName);

        if (!thumbnailName && (thumbnailOne || thumbnailTwo)) {
          setThumbnailName(imageName.split(".")[0]);
        }
        if (!(thumbnailOne || thumbnailTwo)) {
          setVerseImageUploaded({
            id: verseID,
            isUploaded: true,
            verseImage: imageName.split(".")[0],
          });
          if (fileType === "webp") {
            setVerseWEBPImageValid(true);
          } else {
            setVerseJPGImageValid(true);
          }
        }
        setImageTypeValid(true);
        setShowAlert(false);
        setIsUploading(false);
        setUploadFinished(true);
        if (thumbnailOne) {
          setThumbnailOneValid(true);
        }
        if (thumbnailTwo) {
          setThumbnailTwoValid(true);
        }
      } else {
        setSelectedImage();
        setImageTypeValid(false);
        setShowAlert(true);
      }
    }
  };

  return (
    <Fragment>
      <Form.Label>
        {fileType.toUpperCase()} típusú kép, (szélesség*magasság:
        {thumbnailOne || thumbnailTwo ? "183px*183px" : "250px*340px"})
      </Form.Label>
      {uploadFinished ||
      (thumbnailOne && thumbnailOneValid) ||
      (thumbnailTwo && thumbnailTwoValid) ? (
        <Alert variant="success" className="text-center text-uppercase">
          {thumbnailOne && "WEBP Borító"} {thumbnailTwo && "JPG Borító"}Kep
          feltöltve
        </Alert>
      ) : (
        <></>
      )}

      <Form.Control
        type="file"
        onChange={handleImageSelection}
        disabled={isDisabled}
      />

      {!imageTypeValid && showAlert && (
        <Alert
          variant={imageTypeValid ? "success" : "danger"}
          onClose={() => setShowAlert(false)}
          dismissible
        >
          {!imageTypeValid &&
            `Kérlek ellenőrízd hogy ${fileType.toUpperCase()} típusú képet
          választottál-e`}
        </Alert>
      )}
      {isUploading && (
        <Row>
          <SpinnerOverlay spinnerText="kép feltőltése folyamatban" />
        </Row>
      )}
      {selectedImage && (
        <Row className="justify-content-center my-4">
          <ImageContainer thumbnail={thumbnailOne || thumbnailTwo}>
            <Image src={previewSRC} />
          </ImageContainer>
        </Row>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  thumbnailOneValid: selectIsThumbnailOneValid(state),
  thumbnailTwoValid: selectIsThumbnailTwoValid(state),
  thumbnailName: selectThumbnail(state),
  verseList: selectVerseList(state),
  slug: selectSlug(state),
});

const mapDispatchToProps = (dispatch) => ({
  setThumbnailOneValid: (isValid) => dispatch(setThumbnailOneValid(isValid)),
  setThumbnailTwoValid: (isValid) => dispatch(setThumbnailTwoValid(isValid)),
  setThumbnailName: (thumbnailName) => dispatch(setThumbnail(thumbnailName)),
  setVerseImageUploaded: (verseIDAndisUploaded) =>
    dispatch(setVerseImageUploaded(verseIDAndisUploaded)),
  setVerseWEBPImageValid: (isValid) =>
    dispatch(setVerseWEBPImageValid(isValid)),
  setVerseJPGImageValid: (isValid) => dispatch(setVerseJPGImageValid(isValid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageFormField);
