import React, { Fragment, useEffect, useState } from "react";
import { Alert, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import {
  checkTitleAndSlugValid,
  setTitleAndSlugStart,
  setTitleAndSlugValid,
} from "../../redux/editor/editor.actions";
import {
  selectIsTitleAndSlugValid,
  selectSlug,
  selectTitle,
} from "../../redux/editor/editor.selectors";
import TextFormField from "../TextFormField/TextFormField";

const TitleAndSlugFormField = ({
  title,
  slug,
  isTitleAndSlugValid,
  setTitleAndSlug,
  checkTitleAndSlug,
}) => {
  const [isValidating, setIsValidating] = useState(false);

  const generateSlug = (text) => {
    return text
      .toString() // Cast to string
      .toLowerCase() // Convert the string to lowercase letters
      .normalize("NFD") // The normalize() method returns the Unicode Normalization Form of a given string.
      .trim() // Remove whitespace from both sides of a string
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      .replace(/\-\-+/g, "-"); // Replace multiple - with single -
  };

  const handleTitleChange = (event) => {
    const currentSlug = generateSlug(event.target.value);
    setTitleAndSlug({ title: event.target.value, slug: currentSlug });
    setIsValidating(true);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      checkTitleAndSlug({ title, slug });
      setIsValidating(false);
    }, 2000);

    return () => clearTimeout(delayDebounceFn);
  }, [slug]);

  return (
    <Fragment>
      <TextFormField
        label="Mese Cime"
        name="title"
        value={title}
        required
        handleChange={handleTitleChange}
        isValid={isTitleAndSlugValid}
      />
      <h2>Slug: {slug}</h2>{" "}
      <Alert
        variant={isTitleAndSlugValid ? "success" : "danger"}
        className="text-center"
      >
        {isValidating ? (
          <Spinner animation="border" role="status" />
        ) : (
          <Fragment>
            {slug.length < 5
              ? "Cim tul rovid"
              : !isTitleAndSlugValid
              ? "Ilyen SLug már létezik, kérlek változtass a címen"
              : slug.length >= 5 && isTitleAndSlugValid && "Jó Slug :)"}
          </Fragment>
        )}
      </Alert>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  title: selectTitle(state),
  slug: selectSlug(state),
  isTitleAndSlugValid: selectIsTitleAndSlugValid(state),
});

const mapStateToDispatch = (dispatch) => ({
  setTitleAndSlug: ({ title, slug }) =>
    dispatch(setTitleAndSlugStart({ title, slug })),
  setTitleAndSlugValid: (isValid) => dispatch(setTitleAndSlugValid(isValid)),
  checkTitleAndSlug: ({ title, slug }) =>
    dispatch(checkTitleAndSlugValid({ title, slug })),
});

export default connect(
  mapStateToProps,
  mapStateToDispatch
)(TitleAndSlugFormField);
