import React, { Fragment, useEffect, useState } from "react";
import {
  selectDraftPoems,
  selectPoemsLoading,
  selectPublishedPoems,
  selectVerifiedPoems,
} from "../../redux/poems/poems.selectors";
import { loadPoemsStart } from "../../redux/poems/poems.actions";
import { connect } from "react-redux";
import PoemsListItem from "../PoemsListItem/PoemsListItem";
import { Button, Col, Container, InputGroup, Row } from "react-bootstrap";
import SpinnerOverlay from "../SpinnerOverlay/SpinnerOverlay";

const PoemsList = ({
  loadPoems,
  draftPoems,
  verifiedPoems,
  publishedPoems,
  poemsLoading,
}) => {
  const [showDraft, setShowDraft] = useState(true);
  const [showVerified, setShowVerified] = useState(true);
  const [showPublished, setShowPublished] = useState(true);
  useEffect(() => {
    loadPoems();
  }, []);

  const handleSelectAll = () => {
    setShowDraft(true);
    setShowVerified(true);
    setShowPublished(true);
  };

  const handleSelectDraft = () => {
    setShowDraft(true);
    setShowVerified(false);
    setShowPublished(false);
  };
  const handleSelectVerified = () => {
    setShowDraft(false);
    setShowVerified(true);
    setShowPublished(false);
  };
  const handleSelectPublished = () => {
    setShowDraft(false);
    setShowVerified(false);
    setShowPublished(true);
  };

  return (
    <Container>
      <Row>
        <h1>Mesék</h1>
      </Row>
      <Row>
        <Col md={3}>
          <InputGroup className="mb-3">
            <Button
              variant="outline-primary"
              id="button-addon1"
              onClick={handleSelectAll}
            >
              Összes mese
            </Button>
            <InputGroup.Checkbox
              checked={showDraft && showVerified && showPublished}
              onChange={handleSelectAll}
            />
          </InputGroup>
        </Col>
        <Col md={3}>
          <InputGroup className="mb-3">
            <Button
              variant="outline-primary"
              id="button-addon1"
              onClick={handleSelectDraft}
            >
              Piszkozatok
            </Button>
            <InputGroup.Checkbox
              checked={showDraft}
              onChange={handleSelectDraft}
            />
          </InputGroup>
        </Col>
        <Col md={3}>
          {" "}
          <InputGroup className="mb-3">
            <Button
              variant="outline-primary"
              id="button-addon1"
              onClick={handleSelectVerified}
            >
              Ellenőrzött
            </Button>
            <InputGroup.Checkbox
              checked={showVerified}
              onChange={handleSelectVerified}
            />
          </InputGroup>
        </Col>
        <Col md={3}>
          {" "}
          <InputGroup className="mb-3">
            <Button
              variant="outline-primary"
              id="button-addon1"
              onClick={handleSelectPublished}
            >
              Publikált
            </Button>
            <InputGroup.Checkbox
              checked={showPublished}
              onChange={handleSelectPublished}
            />
          </InputGroup>
        </Col>
      </Row>
      {poemsLoading ? (
        <SpinnerOverlay spinnerText="Mesék betöltése folyamatban ..." />
      ) : (
        <Fragment>
          {showDraft && (
            <Fragment>
              <Row id="piszkozatok">
                <h2>Piszkozatok</h2>
              </Row>
              <Row>
                {draftPoems.length ? (
                  <Fragment>
                    {draftPoems.map((poem) => (
                      <PoemsListItem key={poem.id} poem={poem} />
                    ))}
                  </Fragment>
                ) : (
                  <h3>Nincs jelenleg piszkozat</h3>
                )}
              </Row>
            </Fragment>
          )}
          {showVerified && (
            <Fragment>
              <Row id="ellenőrzött">
                <h2>Ellenőrzött</h2>
              </Row>
              <Row>
                {verifiedPoems.length ? (
                  <Fragment>
                    {verifiedPoems.map((poem) => (
                      <PoemsListItem key={poem.id} poem={poem} />
                    ))}
                  </Fragment>
                ) : (
                  <h3>Nincs jelenleg ellenőrzött mese</h3>
                )}
              </Row>
            </Fragment>
          )}
          {showPublished && (
            <Fragment>
              <Row id="publikált">
                <h2>Publikált</h2>
              </Row>
              <Row>
                {publishedPoems.length ? (
                  <Fragment>
                    {publishedPoems.map((poem) => (
                      <PoemsListItem key={poem.id} poem={poem} />
                    ))}
                  </Fragment>
                ) : (
                  <h3>Nincs jelenleg publikált mese</h3>
                )}
              </Row>
            </Fragment>
          )}
        </Fragment>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  draftPoems: selectDraftPoems(state),
  verifiedPoems: selectVerifiedPoems(state),
  publishedPoems: selectPublishedPoems(state),
  poemsLoading: selectPoemsLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  loadPoems: () => dispatch(loadPoemsStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PoemsList);
