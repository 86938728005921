import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import DashBoardPage from "./routes/DashBoardPage/DashBoardPage";
import EditPoemPage from "./routes/EditPoemPage/EditPoemPage";
import PoemsListPage from "./routes/PoemsListPage/PoemsListPage";
import PoemPage from "./routes/PoemPage/PoemPage";
import Navigation from "./routes/Navigation/Navigation";
import AddPoemPage from "./routes/AddPomePage/AddPoemPage";
import SignInPage from "./routes/SignInPage/SignInPage";
import {
  db,
  onAuthStateChangedListener,
} from "./utils/firebase/firebase.utils";
import { connect } from "react-redux";
import { checkCurrentUser } from "./redux/user/user.actions";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "./global-styles/globalStyles";
import { selectCurrentTheme } from "./redux/theme/theme.selectors";
import { loadThemesStart } from "./redux/theme/theme.actions";
import { collection, onSnapshot, query } from "firebase/firestore";
import { fetchPoemsStart } from "./redux/poems/poems.actions";

const App = ({ checkCurrentUser, currentTheme, loadThemes, fetchPoems }) => {
  useEffect(() => {
    const realtimePoemListChangesListener = async () => {
      const q = query(collection(db, "poems"));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        fetchPoems();
      });
    };
    realtimePoemListChangesListener();
  }, []);
  useEffect(() => {
    loadThemes();
    const unsubscribe = onAuthStateChangedListener(
      (user) => async () => {
        if (user) {
          checkCurrentUser();
        }
      },
      []
    );
    return unsubscribe;
  });

  return (
    <ThemeProvider theme={currentTheme}>
      <GlobalStyle />
      <Routes>
        <Route path="/" element={<Navigation />}>
          <Route index element={<DashBoardPage />} />
          <Route path="bejelentkezes" element={<SignInPage />} />
          <Route path="mesek" element={<PoemsListPage />} />

          <Route path="mesek/:poemSlug" element={<PoemPage />} />
          <Route path="mesek/:poemSlug/szerkeszt" element={<EditPoemPage />} />

          <Route path="uj-mese" element={<AddPoemPage />} />
          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <p>There's nothing here!</p>
              </main>
            }
          />
        </Route>
      </Routes>
    </ThemeProvider>
  );
};

const mapDispatchToProps = (dispatch) => ({
  checkCurrentUser: () => dispatch(checkCurrentUser()),
  loadThemes: () => dispatch(loadThemesStart()),
  fetchPoems: () => dispatch(fetchPoemsStart()),
});

const mapStateToProps = (state) => ({
  currentTheme: selectCurrentTheme(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
