// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
} from "firebase/auth";

import { getStorage, ref, uploadBytes } from "firebase/storage";

import {
  getFirestore,
  doc,
  getDoc,
  collection,
  query,
  getDocs,
  where,
  setDoc,
  deleteDoc,
  addDoc,
  updateDoc,
} from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: "AIzaSyAUbYQYxdS4_8yU35lT-kDDNZNGFAGGcNY",
  authDomain: "versesmesek-706db.firebaseapp.com",
  projectId: "versesmesek-706db",
  storageBucket: "versesmesek-706db.appspot.com",
  messagingSenderId: "1090190462438",
  appId: "1:1090190462438:web:1373d3147b6b7a07529a27",
};

// firebase deploy --only hosting:admin-versesmesek

// const firebaseConfig = {
//   apiKey: "AIzaSyAf3KPVryf-ozFFTv0ONOkpqj8VUP0A52E",
//   authDomain: "vs-mesek-dev.firebaseapp.com",
//   projectId: "vs-mesek-dev",
//   storageBucket: "vs-mesek-dev.appspot.com",
//   messagingSenderId: "561872966371",
//   appId: "1:561872966371:web:39868f491e40576afa6bd6",
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore();

export const auth = getAuth();

const storage = getStorage();

export const uploadPoemImageToFirebase = async (
  fileToUpload,
  poemSlug,
  imageName
) => {
  const storageRef = ref(storage, `static/images/${poemSlug}/${imageName}`);
  uploadBytes(storageRef, fileToUpload).then((snapshot) => {
    console.log("Uploaded a blob or file!");
  });
};

export const signInAuthUserWithEmailAndPassword = async (email, password) => {
  if (!email || !password) return;

  return await signInWithEmailAndPassword(auth, email, password);
};

export const getCurrentUserAccessLevel = async () => {
  const user = auth.currentUser;
  if (user !== null) {
    const docRef = doc(db, "users", user.uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      alert("no user associated with this email");
    }
  }
};

export const fetchPoemsFromFirestore = async () => {
  const q = query(collection(db, "poems"));
  const querySnapshot = await getDocs(q);
  const allPoems = [];
  querySnapshot.forEach((doc) => {
    allPoems.push(doc.data());
  });
  return allPoems;
};

export const fetchCurrentPoemFromFirestore = async (poemSlug) => {
  let poem = {};
  const q = query(collection(db, "poems"), where("slug", "==", poemSlug));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    poem = doc.data();
  });
  return poem;
};

export const onAuthStateChangedListener = (callback) =>
  onAuthStateChanged(auth, callback);

// LOG EDITOR STATE

export const uploadEditorStateToFireStore = async (editor) => {
  const id = editor.editorID;
  await setDoc(doc(db, "editorInstances", id), editor);
};

// LOG USER ACTION
export const logUserActionOnPoemsInFirestore = async (
  poem,
  userEmail,
  userAction
) => {
  console.log("fgv hgi");
  console.log(poem);
  console.log(userEmail);
  console.log(userAction);
  const log = {
    userEmail: userEmail,
    action: userAction,
    poemID: poem.id,
    slug: poem.slug,
  };
  await addDoc(collection(db, "userActions"), log);
};

// UPLOAD POEM
export const saveEditedPoemInFirestore = async (editor, userEmail) => {
  const poem = {
    id: editor.editorID,

    createdBy: userEmail,
    createdOn: new Date().toISOString(),

    lastUpdatedOn: new Date().toISOString(),

    isDraft: true,

    isVerified: false,
    verifiedBy: null,
    verifiedOn: null,

    isPublished: false,
    publishedBy: null,
    publishedOn: null,

    isCheckedOut: false,
    checkedOutBy: null,
    checkedOutOn: null,

    title: editor.title,
    slug: editor.slug,
    thumbnail: editor.thumbnail,
    verseList: editor.verseList,
  };
  const id = editor.editorID;
  await setDoc(doc(db, "poems", id), poem);
};

// DELETE POEM

export const deletePoemInFirestore = async (poem) => {
  await deleteDoc(doc(db, "poems", poem.id));
};

// MARK POEM AS VERIFIED

export const markPoemAsVerifiedInFirestore = async (poem, userEmail) => {
  const poemRef = doc(db, "poems", poem.id);

  await updateDoc(poemRef, {
    isVerified: true,
    isDraft: false,
    verifiedBy: userEmail,
    verifiedOn: new Date().toISOString(),
  });
};

// PUBLISH POEM

export const markPoemAsPublishedInFirestore = async (poem, userEmail) => {
  const poemRef = doc(db, "poems", poem.id);

  await updateDoc(poemRef, {
    isPublished: true,
    isVerified: false,
    publishedBy: userEmail,
    publishedOn: new Date().toISOString(),
  });
};
// UNPUBLISH POEM

export const markPoemAsUnPublishedInFirestore = async (poem, userEmail) => {
  const poemRef = doc(db, "poems", poem.id);

  await updateDoc(poemRef, {
    isPublished: false,
    isVerified: true,
  });
};

// UPDATE EXISTING DOCS WITH ADDED FIELDS

// export const updateExistingDocumentsWithNewFields = async () => {
//   const q = query(collection(db, "poems"));
//   const querySnapshot = await getDocs(q);

//   const idList = [];

//   querySnapshot.forEach(async (doc) => {
//     idList.push(doc._key.path.segments[6]);
//   });

//   idList.forEach(async (id) => {
//     const poemRef = doc(db, "poems", id);

//     await updateDoc(poemRef, {
//       createdBy: "penyo.bigyo@gmail.com",
//       createdOn: new Date().toISOString(),

//       lastUpdatedOn: new Date().toISOString(),

//       isDraft: false,

//       isVerified: false,
//       verifiedBy: "penyo.bigyo@gmail.com",
//       verifiedOn: new Date().toISOString(),

//       publishedBy: "penyo.bigyo@gmail.com",
//       publishedOn: new Date().toISOString(),

//       isCheckedOut: false,
//       checkedOutBy: null,
//       checkedOutOn: null,
//     });
//   });
//   return;
// };

//

// export const realtimePoemListChangesListener = async () => {
//   const q = query(collection(db, "poems"));
//   const unsubscribe = onSnapshot(q, (querySnapshot) => {
//     const poems = [];
//     querySnapshot.forEach((doc) => {
//       poems.push(doc.data().isPublished);
//     });
//     console.log("poems published property ", poems.join(", "));
//   });

// };
