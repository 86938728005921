const UserActionTypes = {
  SET_CURRENT_USER_START: "SET_CURRENT_USER_START",
  SET_CURRENT_USER_SUCCESS: "SET_CURRENT_USER_SUCCESS",
  SET_CURRENT_USER_FAIL: "SET_CURRENT_USER_FAIL",

  CHECK_CURRENT_USER: "SET_CURRENT_USER",
  CLEAR_CURRENT_USER: "CLEAR_CURRENT_USER",

  SIGN_IN_WITH_EMAIL_PASSWORD_START: "SIGN_IN_WITH_EMAIL_PASSWORD_START",
  SIGN_IN_WITH_EMAIL_PASSWORD_SUCCESS: "SIGN_IN_WITH_EMAIL_PASSWORD_SUCCESS",
  SIGN_IN_WITH_EMAIL_PASSWORD_FAIL: "SIGN_IN_WITH_EMAIL_PASSWORD_FAIL",

  SIGN_OUT_START: "SIGN_OUT_START",
  SIGN_OUT_SUCCESS: "SIGN_OUT_SUCCESS",
  SIGN_OUT_FAIL: "SIGN_OUT_FAIL",

  LOG_USER_ACTION_START: "LOG_USER_ACTION_START",
  LOG_USER_ACTION_SUCCESS: "LOG_USER_ACTION_SUCCESS",
  LOG_USER_ACTION_FAIL: "LOG_USER_ACTION_FAIL",
};

export default UserActionTypes;
