import { all, call } from "redux-saga/effects";
import { userSagas } from "./user/user.sagas";
import { poemsSagas } from "./poems/poems.sagas";
import { themeSagas } from "./theme/theme.sagas";
import { editorSagas } from "./editor/editor.sagas";
import { poemSagas } from "./poem/poem.sagas";
export default function* rootSaga() {
  yield all([
    call(userSagas),
    call(poemsSagas),
    call(themeSagas),
    call(editorSagas),
    call(poemSagas),
  ]);
}
