import React from "react";
import styled from "styled-components";
import { Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";

export const StyledCounter = styled.div`
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

export const CounterCircle = styled.div`
  height: 150px;
  width: 150px;
  background-color: ${(props) => props.theme.secondaryBackgroundColor};
  border-radius: 50%;
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-size: 60px;
    padding-top: 30px;
  }
`;

const CounterDashBoardItem = ({ title, number, linkName, linkUrl }) => {
  return (
    <Card>
      <Card.Body>
        <Card.Title className="text-center">{title}</Card.Title>
        <StyledCounter>
          <CounterCircle>
            <p>{number}</p>
          </CounterCircle>
        </StyledCounter>
        {linkName && (
          <Link to={linkUrl}>
            <Button variant="outline-primary" className="w-100">
              {linkName}
            </Button>
          </Link>
        )}
      </Card.Body>
    </Card>
  );
};

export default CounterDashBoardItem;
