import React, { Fragment, useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { connect } from "react-redux";
import {
  setVerseImageAlt,
  setVerseImageAltValid,
} from "../../redux/editor/editor.actions";
import {
  selectCurrentVerseImageAltValid,
  selectVerseList,
} from "../../redux/editor/editor.selectors";
import SpinnerOverlay from "../SpinnerOverlay/SpinnerOverlay";
import TextFormField from "../TextFormField/TextFormField";

const VerseImageAltFormField = ({
  verseList,
  verseID,
  setVerseImageAlt,
  setVerseImageAltValid,
  isValid,
}) => {
  const [imageAlt, setImageAlt] = useState("");
  const [isValidatingImageALt, setIsvalidatingImageAlt] = useState(false);

  useEffect(() => {
    setIsvalidatingImageAlt(true);
    const currentVerse = verseList.find((verse) => verse.id === verseID);
    if (currentVerse !== undefined) {
      const altText = currentVerse.verseImageAlt;
      setImageAlt(altText);
      if (altText.length > 4) {
        setVerseImageAltValid(true);
      } else {
        setVerseImageAltValid(false);
      }
    } else {
      setImageAlt("");
    }
    setIsvalidatingImageAlt(false);
  }, [verseID]);

  const handleImageAltTextChange = (e) => {
    e.preventDefault();
    setIsvalidatingImageAlt(true);
    setImageAlt(e.target.value);
    handleImageAltChange(e);
  };

  const handleImageAltChange = (e) => {
    const delayDebounceFn = setTimeout(() => {
      setVerseImageAlt({ id: verseID, imageAlt: e.target.value });
      if (e.target.value.length > 4) {
        setVerseImageAltValid(true);
      } else {
        setVerseImageAltValid(false);
      }
      setIsvalidatingImageAlt(false);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  };
  return (
    <Fragment>
      <Alert
        variant={
          isValidatingImageALt ? "warning" : isValid ? "success" : "danger"
        }
        className="text-center text-uppercase"
      >
        {isValidatingImageALt ? (
          <SpinnerOverlay spinnerText="pillanat..." />
        ) : !isValid ? (
          "Képek feltöltéséhez kérlek adj meg egy legalabb 5 karakter hosszú alternatív kép nevet"
        ) : (
          "Alternatív kép név feltöltve"
        )}
      </Alert>

      <TextFormField
        label="Alternativ kep nev"
        name="imageAlt"
        value={imageAlt}
        handleChange={handleImageAltTextChange}
      />
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  verseList: selectVerseList(state),
  isValid: selectCurrentVerseImageAltValid(state),
});

const mapDispatchToProps = (dispatch) => ({
  setVerseImageAlt: (verseIDAndaltText) =>
    dispatch(setVerseImageAlt(verseIDAndaltText)),
  setVerseImageAltValid: (isValid) => dispatch(setVerseImageAltValid(isValid)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerseImageAltFormField);
