import UserActionTypes from "./user.types";

export const setCurrentUserStart = () => ({
  type: UserActionTypes.SET_CURRENT_USER_START,
});

export const setCurrentUserSuccess = (userInfo) => ({
  type: UserActionTypes.SET_CURRENT_USER_SUCCESS,
  payload: userInfo,
});

export const setCurrentUserFail = () => ({
  type: UserActionTypes.SET_CURRENT_USER_FAIL,
});
export const checkCurrentUser = () => ({
  type: UserActionTypes.CHECK_CURRENT_USER,
});

export const clearCurrentUser = () => ({
  type: UserActionTypes.CLEAR_CURRENT_USER,
});

export const signInWithEmailPasswordStart = (loginDetails) => ({
  type: UserActionTypes.SIGN_IN_WITH_EMAIL_PASSWORD_START,
  payload: loginDetails,
});

export const signInWithEmailPasswordSuccess = (userDetails) => ({
  type: UserActionTypes.SIGN_IN_WITH_EMAIL_PASSWORD_SUCCESS,
  payload: userDetails,
});

export const signInWithEmailPasswordFail = () => ({
  type: UserActionTypes.SIGN_IN_WITH_EMAIL_PASSWORD_FAIL,
});

export const signOutStart = () => ({
  type: UserActionTypes.SIGN_OUT_START,
});

export const signOutSuccess = (userInfo) => ({
  type: UserActionTypes.SIGN_OUT_SUCCESS,
});

export const signOutFail = () => ({
  type: UserActionTypes.SIGN_OUT_FAIL,
});

export const logUserActionStart = (poemAndUserEmailAndUserAction) => ({
  type: UserActionTypes.LOG_USER_ACTION_START,
  payload: poemAndUserEmailAndUserAction,
});

export const logUserActionSuccess = () => ({
  type: UserActionTypes.LOG_USER_ACTION_SUCCESS,
});

export const logUserActionFail = (errorMessage) => ({
  type: UserActionTypes.LOG_USER_ACTION_FAIL,
  payload: errorMessage,
});
