import { createSelector } from "reselect";

const selectPoems = (state) => state.poems;

export const selectAllPoems = createSelector(
  [selectPoems],
  (poems) => poems.allPoems
);

export const selectDraftPoems = createSelector(
  [selectPoems],
  (poems) => poems.draftPoems
);
export const selectVerifiedPoems = createSelector(
  [selectPoems],
  (poems) => poems.verifiedPoems
);
export const selectPublishedPoems = createSelector(
  [selectPoems],
  (poems) => poems.publishedPoems
);

export const selectPoemsLoading = createSelector(
  [selectPoems],
  (poems) => poems.poemsLoading
);

export const selectDraftPoemsCount = createSelector(
  [selectPoems],
  (poems) => poems.draftPoems.length
);
export const selectVerifiedPoemsCount = createSelector(
  [selectPoems],
  (poems) => poems.verifiedPoems.length
);
export const selectPublishedPoemsCount = createSelector(
  [selectPoems],
  (poems) => poems.publishedPoems.length
);
