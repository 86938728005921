import PoemActionTypes from "./poem.types";

// DELETE POEM

export const deletePoemStart = (poem) => ({
  type: PoemActionTypes.DELETE_POEM_START,
  payload: poem,
});

export const deletePoemSuccess = (poemAndUserEmailAndUserAction) => ({
  type: PoemActionTypes.DELETE_POEM_SUCCESS,
  payload: poemAndUserEmailAndUserAction,
});

export const deletePoemFail = (errorMessage) => ({
  type: PoemActionTypes.DELETE_POEM_FAIL,
  payload: errorMessage,
});

export const markPoemAsVerifiedStart = (poem) => ({
  type: PoemActionTypes.MARK_POEM_AS_VERIFIED_START,
  payload: poem,
});

export const markPoemAsVerifiedSuccess = (poemAndUserEmailAndUserAction) => ({
  type: PoemActionTypes.MARK_POEM_AS_VERIFIED_SUCCESS,
  payload: poemAndUserEmailAndUserAction,
});

export const markPoemAsVerifiedFail = (errorMessage) => ({
  type: PoemActionTypes.MARK_POEM_AS_VERIFIED_FAIL,
  payload: errorMessage,
});

export const publishPoemStart = (poem) => ({
  type: PoemActionTypes.PUBLISH_POEM_START,
  payload: poem,
});

export const publishPoemSuccess = (poemAndUserEmailAndUserAction) => ({
  type: PoemActionTypes.PUBLISH_POEM_SUCCESS,
  payload: poemAndUserEmailAndUserAction,
});

export const publishPoemFail = (errorMessage) => ({
  type: PoemActionTypes.PUBLISH_POEM_FAIL,
  payload: errorMessage,
});

export const unPublishPoemStart = (poem) => ({
  type: PoemActionTypes.UN_PUBLISH_POEM_START,
  payload: poem,
});

export const unPublishPoemSuccess = (poemAndUserEmailAndUserAction) => ({
  type: PoemActionTypes.UN_PUBLISH_POEM_SUCCESS,
  payload: poemAndUserEmailAndUserAction,
});

export const unPublishPoemFail = (errorMessage) => ({
  type: PoemActionTypes.UN_PUBLISH_POEM_FAIL,
  payload: errorMessage,
});
