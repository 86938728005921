import PoemsActionTypes from "./poems.types";

const INITIAL_STATE = {
  allPoems: [],
  draftPoems: [],
  verifiedPoems: [],
  publishedPoems: [],
  poemsLoading: false,
  poemsFetched: false,
  poemLoading: false,
  errorMessage: "",
};

const poemsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PoemsActionTypes.LOAD_POEMS_START:
      return {
        ...state,
        poemsLoading: true,
      };
    case PoemsActionTypes.LOAD_POEMS_SUCCESS:
      return {
        ...state,
        poemsLoading: false,
      };
    case PoemsActionTypes.LOAD_POEMS_FAIL:
      return {
        ...state,
        poemsLoading: false,
        errorMessage: action.payload,
      };
    case PoemsActionTypes.FETCH_POEMS_START:
      return {
        ...state,
        poemsFetched: false,
      };
    case PoemsActionTypes.FETCH_POEMS_SUCCESS:
      return {
        ...state,
        allPoems: action.payload,
        draftPoems: action.payload.filter((poem) => poem.isDraft === true),
        verifiedPoems: action.payload.filter(
          (poem) => poem.isVerified === true
        ),
        publishedPoems: action.payload.filter(
          (poem) => poem.isPublished === true
        ),
        poemsFetched: true,
      };
    case PoemsActionTypes.FETCH_POEMS_FAIL:
      return {
        ...state,
        poemsFetched: false,
        errorMessage: action.payload,
      };
    case PoemsActionTypes.LOAD_POEM_START:
      return {
        ...state,
        poemLoading: true,
      };
    case PoemsActionTypes.LOAD_POEM_SUCCESS:
      return {
        ...state,
        poemLoading: false,
      };
    case PoemsActionTypes.LOAD_POEM_FAIL:
      return {
        ...state,
        poemLoading: false,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default poemsReducer;
