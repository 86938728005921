const PoemActionTypes = {
  DELETE_POEM_START: "DELETE_POEM_START",
  DELETE_POEM_SUCCESS: "DELETE_POEM_SUCCESS",
  DELETE_POEM_FAIL: "DELETE_POEM_FAIL",

  MARK_POEM_AS_VERIFIED_START: "MARK_POEM_AS_VERIFIED_START",
  MARK_POEM_AS_VERIFIED_SUCCESS: "MARK_POEM_AS_VERIFIED_SUCCESS",
  MARK_POEM_AS_VERIFIED_FAIL: "MARK_POEM_AS_VERIFIED_FAIL",

  PUBLISH_POEM_START: "PUBLISH_POEM_START",
  PUBLISH_POEM_SUCCESS: "PUBLISH_POEM_SUCCESS",
  PUBLISH_POEM_FAIL: "PUBLISH_POEM_FAIL",

  UN_PUBLISH_POEM_START: "UN_PUBLISH_POEM_START",
  UN_PUBLISH_POEM_SUCCESS: "UN_PUBLISH_POEM_SUCCESS",
  UN_PUBLISH_POEM_FAIL: "UN_PUBLISH_POEM_FAIL",
};

export default PoemActionTypes;
