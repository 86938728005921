import EditorActionTypes from "./editor.types";

// EDITOR

export const startEditorStart = () => ({
  type: EditorActionTypes.START_EDITOR_START,
});
export const startEditorSuccess = () => ({
  type: EditorActionTypes.START_EDITOR_SUCCESS,
});
export const startEditorFail = (errorMessage) => ({
  type: EditorActionTypes.START_EDITOR_FAIL,
  payload: errorMessage,
});

export const setEditorLoading = (payload) => ({
  type: EditorActionTypes.SET_EDITOR_LOADING,
  payload: payload,
});

export const checkIfReadyToProceedStart = () => ({
  type: EditorActionTypes.CHECK_IF_READY_TO_PROCEED_START,
});

export const checkIfReadyToProceedSuccess = () => ({
  type: EditorActionTypes.CHECK_IF_READY_TO_PROCEED_SUCCESS,
});

export const checkIfReadyToProceedFail = (errorMessage) => ({
  type: EditorActionTypes.CHECK_IF_READY_TO_PROCEED_FAIL,
  payload: errorMessage,
});

export const setReadyToProceed = (isReady) => ({
  type: EditorActionTypes.SET_READY_TO_PROCEED,
  payload: isReady,
});

// DRAFT

export const createDraft = () => ({
  type: EditorActionTypes.CREATE_DRAFT,
});

export const clearDraftStart = () => ({
  type: EditorActionTypes.CLEAR_DRAFT_START,
});
export const clearDraftSuccess = () => ({
  type: EditorActionTypes.CLEAR_DRAFT_SUCCESS,
});
export const clearDraftFail = (errorMessage) => ({
  type: EditorActionTypes.CLEAR_DRAFT_FAIL,
  payload: errorMessage,
});

// FORM STEPS

export const nextStepStart = () => ({
  type: EditorActionTypes.NEXT_STEP_START,
});
export const nextStepSuccess = (currentStep) => ({
  type: EditorActionTypes.NEXT_STEP_SUCCESS,
  payload: currentStep,
});
export const nextStepFail = (errorMessage) => ({
  type: EditorActionTypes.NEXT_STEP_FAIL,
  payload: errorMessage,
});

export const prevStepStart = () => ({
  type: EditorActionTypes.PREV_STEP_START,
});
export const prevStepSuccess = (currentStep) => ({
  type: EditorActionTypes.PREV_STEP_SUCCESS,
  payload: currentStep,
});
export const prevStepFail = (errorMessage) => ({
  type: EditorActionTypes.PREV_STEP_FAIL,
  payload: errorMessage,
});

// TITLE AND SLUG

export const setTitleAndSlugStart = (titleAndSlug) => ({
  type: EditorActionTypes.SET_TITLE_AND_SLUG_START,
  payload: titleAndSlug,
});

export const setTitleAndSlugSuccess = (titleAndSlug) => ({
  type: EditorActionTypes.SET_TITLE_AND_SLUG_SUCCESS,
  payload: titleAndSlug,
});
export const checkTitleAndSlugValid = (titleAndSlug) => ({
  type: EditorActionTypes.CHECK_TITLE_AND_SLUG_VALID,
  payload: titleAndSlug,
});

export const setTitleAndSlugValid = (isValid) => ({
  type: EditorActionTypes.SET_TITLE_AND_SLUG_VALID,
  payload: isValid,
});

// THUMBNAIL

export const setThumbnail = (thumbnailName) => ({
  type: EditorActionTypes.SET_THUMBNAIL,
  payload: thumbnailName,
});

export const setThumbnailOneValid = (payload) => ({
  type: EditorActionTypes.SET_THUMBNAIL_ONE_VALID,
  payload: payload,
});

export const setThumbnailTwoValid = (isValid) => ({
  type: EditorActionTypes.SET_THUMBNAIL_TWO_VALID,
  payload: isValid,
});

// VERSE

export const createVerseStart = () => ({
  type: EditorActionTypes.CREATE_VERSE_START,
});

export const createVerseSuccess = (verseList) => ({
  type: EditorActionTypes.CREATE_VERSE_SUCCESS,
  payload: verseList,
});

export const createVerseFail = (errorMessage) => ({
  type: EditorActionTypes.CREATE_VERSE_FAIL,
  payload: errorMessage,
});

export const deleteEmptyVerseStart = () => ({
  type: EditorActionTypes.DELETE_EMPTY_VERSE_START,
});

export const deleteEmptyVerseSuccess = (verseList) => ({
  type: EditorActionTypes.DELETE_EMPTY_VERSE_SUCCESS,
  payload: verseList,
});

export const deleteEmptyVerseFail = (errorMessage) => ({
  type: EditorActionTypes.DELETE_EMPTY_VERSE_FAIL,
  payload: errorMessage,
});

// VERSE TEXT

export const setCurrentVerseText = (verseIDAndverseText) => ({
  type: EditorActionTypes.SET_CURRENT_VERSE_TEXT,
  payload: verseIDAndverseText,
});

export const checkCurrentVerseTextOnLoad = (verseText) => ({
  type: EditorActionTypes.CHECK_CURRENT_VERSE_TEXT_ON_LOAD,
  payload: verseText,
});
export const setCurrentVerseTextValid = (isValid) => ({
  type: EditorActionTypes.SET_CURRENT_VERSE_TEXT_VALID,
  payload: isValid,
});
// VERSE IMAGE

export const setVerseImageAlt = (verseIDAndaltText) => ({
  type: EditorActionTypes.SET_VERSE_IMAGE_ALT,
  payload: verseIDAndaltText,
});

export const setVerseImageAltValid = (isValid) => ({
  type: EditorActionTypes.SET_VERSE_IMAGE_ALT_VALID,
  payload: isValid,
});

export const setVerseImageUploaded = (verseIDAndisUploaded) => ({
  type: EditorActionTypes.SET_VERSE_IMAGE_UPLOADED,
  payload: verseIDAndisUploaded,
});
export const setVerseWEBPImageValid = (isValid) => ({
  type: EditorActionTypes.SET_VERSE_WEBP_IMAGE_VALID,
  payload: isValid,
});

export const setVerseJPGImageValid = (isValid) => ({
  type: EditorActionTypes.SET_VERSE_JPG_IMAGE_VALID,
  payload: isValid,
});
// LOG

export const addLogEntry = (userAndAction) => ({
  type: EditorActionTypes.ADD_LOG_ENTRY,
  payload: userAndAction,
});

// SYNC TO FIREBASE

export const syncChangesToFirebaseStart = () => ({
  type: EditorActionTypes.SYNC_CHANGES_TO_FIREBASE_START,
});
export const syncChangesToFirebaseSuccess = () => ({
  type: EditorActionTypes.SYNC_CHANGES_TO_FIREBASE_SUCCESS,
});
export const syncChangesToFirebaseFail = (errorMessage) => ({
  type: EditorActionTypes.SYNC_CHANGES_TO_FIREBASE_FAIL,
  payload: errorMessage,
});
// UPLOAD POEM TO FIREBASE

export const uploadPoemToPoemListInFirestoreStart = () => ({
  type: EditorActionTypes.UPLOAD_EDITED_POEM_TO_POEMLIST_START,
});
export const uploadPoemToPoemListInFirestoreSuccess = () => ({
  type: EditorActionTypes.UPLOAD_EDITED_POEM_TO_POEMLIST_SUCCESS,
});
export const uploadPoemToPoemListInFirestoreFail = (errorMessage) => ({
  type: EditorActionTypes.UPLOAD_EDITED_POEM_TO_POEMLIST_FAIL,
  payload: errorMessage,
});

// ERROR MESSAGES

export const setErrorEntries = (errorMessage) => ({
  type: EditorActionTypes.SET_ERROR_ENTRIES,
  payload: errorMessage,
});
